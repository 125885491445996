#nav-icon {
    width: 60px;
    height: 40px;
    position: relative;
    margin: 10px auto;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
}

#nav-icon span {
    display: block;
    position: absolute;
    height: 7px;
    width: 100%;
    background: $yale-blue;
    border-radius: 7px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}




svg#navhash { 
    width: 50px;
    height: 50px;
    #Bar-4, 
    #Bar-3, 
    #Bar-2, 
    #Bar-1, 
    #Top-Bars, 
    #Bottom-Bars {
		transition: transform 350ms ease-in-out; 
	}
	#Top-Bars { 
			transform-origin: 30px 23px;
	}
	#Bottom-Bars {
		transform-origin: 30px 36px;
	}
}

svg#navhash.active { 
	#Top-Bars { 
		transform: rotateZ(135deg) translateX(4px) translateY(-4px);	
	}
	#Bottom-Bars { 
		transform: rotateZ(225deg) translateX(4px) translateY(3px);
	}
}


div#navhashloc { 
	position: absolute; 
	background: yellow; 
	width: 32px;
	height: 28px;
	top: 16px;
	left: 12px;
	z-index: -1000;
}

.navtrigger button {
    border: none;
    padding: 0px;
    background: none;
    padding-right: 10px;
    padding-bottom: 8px;
    &:focus {
        border: 2px solid $yale-blue;
    }
}


/* Icon */

#nav-icon span:nth-child(1) {
    top: 0px;
}

#nav-icon span:nth-child(2),#nav-icon span:nth-child(3) {
    top: 15px;
}

#nav-icon span:nth-child(4) {
    top: 30px;
}

#nav-icon.open span:nth-child(1) {
    top: 15px;
    width: 0%;
    left: 50%;
}

#nav-icon.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

#nav-icon.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

#nav-icon.open span:nth-child(4) {
    top: 15px;
    width: 0%;
    left: 50%;
}

.mobilemenu .sf-accordion-toggle {
    display: none;
}

#superfish-2-accordion {
    left:auto !important;
    top:auto !important;
    display: block;
    position: relative;
}




.mobilemenu {
    #superfish-2-accordion {
        background-color: #fff;
        padding: 10px;
        margin-bottom: 20px;
        > li {
            padding: 10px;
            a {
                font-family: "YaleNew-Medium", serif;
            }
        }

        > li > a.menuparent {
            background-image: url('../images/blue_positive_accordion_icon.svg');
            background-position: right 10px center;
            background-repeat: no-repeat;
        }

        > li.sf-expanded > a.menuparent {
            background-image: url('../images/red_negative_accordion_icon.svg');
            background-position: right 10px center;
            background-repeat: no-repeat;
        }

        li.sf-expanded > ul {
            background-image: url('../images/mobile_menu_level2.svg');
            background-position: top right;
            background-repeat: no-repeat;
            padding-top: 34px;
        }

        li.sf-expanded > ul > li > ul {
            background: none;
            border-left: 4px solid $yale-med-blue;
            padding-top: 10px;
        }

        .menuparent > ul {
            padding: 10px;
            padding-top: 20px;
            > li {
                margin-bottom: 14px;
                a {
                    font-family: "Mallory", sans-serif;
                    text-decoration: underline;
                    font-size: 18px;
                    line-height: 24px;
                }
            }
        }
    }
    a  {
        font-size: 26px;
        line-height: 31px;
        text-decoration: none;
    }
    .sf-vertical,
    .sf-vertical li {
        width: 100%;
    }
}