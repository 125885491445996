.views-exposed-widgets .views-exposed-widget.views-widget-filter-body_value label,
span.hide {
    display: none;
    visibility: hidden;
}



a {
    transition: color 250ms cubic-bezier(.44,0,0,1);
}

a:hover {
    color: $yale-med-blue;
}

.sitetitle h1 {
    margin-top: 10px;
}

.sitetitle h1 a {
    color: $yale-blue;
    text-decoration: none;
}

.contenttitle h2 {
    color: $yale-blue;
}

div.arrowlink {
    overflow: hidden;
}

div.arrowlink a {
    height: 68px;
    width: 100%;
}



div.arrowlink {
    width: 100%;
    position: relative;
}

div.arrowlink a.flex {
    -webkit-flex-direction: row;
    flex-direction: row;
    width: 100%;
    // > div {
    //     width: auto;
    // }
}

.node-content-news .headertop h2 {
    color: $yale-blue;
}

div.linkbutton a {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    font-family: "Mallory-Book", sans-serif;
    text-decoration: none;
    text-align: left;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
     span {
        //height: 17px;
        font-size: 17px;
        line-height: 22px;
    }
}

div.linkbutton a {
    // display properties
    // display: block;
    width: 312px;
    height: 64px;
    box-sizing: border-box;
    padding: 1rem 3rem 1rem 1.5rem;
    position: relative;
    overflow: hidden;
    -webkit-clip-path:  polygon(0px 0px, 312px 0px, 312px 40px, 288px 64px, 0px 64px);
    clip-path: 		    polygon(0px 0px, 312px 0px, 312px 40px, 288px 64px, 0px 64px);

    // appearance
    background: none;
    box-shadow: inset 0 0 0 3px #fff;

    // text styles
    font-size: 17px;
    line-height: 22px;
    text-align: left;
    color: $yale-blue;

    // animation
    transition: color 250ms ease-in 100ms;

    &:after, &:before {
        @include psuedo-rendering;
    }
    &:before {
        // position the before element to complete the blue border.
        top: 105px;
        left: 140px;
        transform: rotateZ(45deg);
    }
    &:after {
        // position the after element to create the top left corner
        top: -330px;
        left: -240px;
        transform: rotateZ(45deg);

        // animate on mouse-in & mouse-out
        transition: transform 300ms cubic-bezier(0.44,0,0,1);
    }
    &:hover {
        color: $yale-blue;
        text-decoration: underline;
        &:after {
            // transform corner element into background fill.
            transform: rotateZ(45deg) translate(260px, -20px);
        }
    }
}

div.linkbutton.blue a {
    box-shadow: inset 0 0 0 3px $yale-blue;
    color: $yale-blue;
    &:before {
        background: $yale-blue;
    }
    &:after {
        background: $yale-blue;
    }
    &:hover {
        color: #fff;

    }
}

.view-all-news {
  ul.pager {
    margin-top: 50px;
    li {
      margin: 0px;
      padding: 0px;
      border-radius: 5px;
      font-family: "Mallory-Book", sans-serif;
      a {
        text-decoration: none;
        font-family: "Mallory-Book", sans-serif;
        margin: 0px;
        padding: 0px;
        padding: 10px 15px 10px 15px;
      }
      // .pager-next {
      //   a {}
      // }
      // .pager-last {
      //   a {}
      // }
      // .pager-first {
      //   a {}
      // }
      // .pager-previous {
      //   a {}
      // }
      .pager-item {
        margin: 0px 5px;
        a {

          color: $yale-med-blue;
          text-decoration: none;
          padding: 10px 15px 10px 15px;
        }
      }
      .pager-current {
        a {
          color: $yale-med-grey;
        }
      }
    }

    li.pager-item {
      a {
       border: 1px solid $yale-med-blue;
       border-radius: 5px;
       margin: 0px 5px;
      }
      a:focus {
          border: 3px solid $yale-green;
      }
    }

    li.pager-current {
      background-color: $yale-white;
      border: 1px solid $yale-menu;
      border-radius: 5px;
      padding: 10px 15px 10px 15px;
    }

    li.pager-first,
    li.pager-previous,
    li.pager-next,
    li.pager-last {
      border: none;
    }
  }
}


article.node-content-news {
    .newsbyline {
        span.newsdate {
            margin-right: 15px;
            font-size: 14px;
            font-family: "Mallory-Book", sans-serif;
            color: $yale-accessable-brown;
        }
        span.newsauthor {
            font-size: 14px;
            font-family: "Mallory-Book", sans-serif;
            text-transform: uppercase;
            color: $yale-grey;
        }
    }
}


.panel-pane .pane-title {
    display: none;
}

.yalebanner {
    height: 56px;
    background-color: $yale-blue;
    a {
        color: #fff;
        text-decoration: none;
        height: 56px;
        padding: 0px 5px;
        transition: background-color 250ms cubic-bezier(.44,0,0,1);
    }
    a:hover {
        background-color: $yale-med-blue;
    }
    img {
        width: 144px;
        height: 56px;
    }
}

header#header div.mainmenu.flex {
    width: 100%;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
}


div.mainmenu ul li {
    display: inline;
    list-style: none;
    list-style-type: none;
    margin: 0px;
    padding: 0px;
}

header#header div.mainmenu > nav {
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
}

div.mainmenu > nav {
    // border-bottom: 6px solid #ddd;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: right;

    width: 100%;

    li {
        ul > li > a.active {
            border-bottom: none;
            padding-bottom: 0px;
        }
    }

    a {
        font-family: "YaleNew-Roman", serif;
        color: $yale-blue;
        font-size: 26px;
        text-decoration: none;
        padding-left: 15px;
        padding-right: 15px;
        transition: color 250ms cubic-bezier(.44,0,0,1);
    }
    a.active {
        border-bottom: 6px solid $yale-blue;
        padding-bottom: 10px;
    }
    a:hover {
        padding-bottom: 10px;
        color: $yale-med-blue;
    }
}

div#block-superfish-1 {
    width: 100%;
}

ul#superfish-1 {
    border-bottom: 6px solid #ddd;
    height: 42px;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    width: 100%;
    li.menuparent > ul {
        margin-top: -4px;
        width: 300px;
        > li {
            border-bottom: 1px solid #fff;
            padding: 10px;
            background-color: $yale-blue;
            a {
                color: #fff;
                font-family: "YaleNew-Roman", serif;
                font-size: 18px;
                line-height: 24px;
            }
            > a:hover {
                border-bottom: none;
                padding-bottom: 0;
            }
        }
        > li > ul {

            margin-left: 110px;
            margin-top: 0px;
        }
    }
}

article.node-content-news,
body.node-type-content-landing-page main,
body.node-type-content-landing-pagenotriangle main {

    background-position: top right 25px;
    background-repeat: no-repeat;
    background-size: auto;
    position: relative;

    .backgroundimage {
        position: absolute;
        right: 25px;
        z-index: -100;
        height: 400px;

        .bgimagegradient {
            position: absolute;
            height: 100%;
            width: 100%;
            z-index: 1;
            background-image: linear-gradient(to right, white 10%, rgba(0, 53, 107, .5));
            background-size: 100%;
        }
        > img {
            position: relative;
            right: 0px;
            top: 0px;
            z-index: 0;
            vertical-align: bottom;
        }
    }

}

div.breadcrumb {
    font-family: "Mallory-Book", sans-serif;
    font-size: 17px;
    text-decoration: none;
    color: $black;

    ul, li {
        display: inline;
        margin: 0px;padding: 0px;
    }
    li {
        background-image: url('../images/small_blue_arrow.svg');
        background-repeat: no-repeat;
        background-position: right;
        padding-right: 20px;
        background-size: 14px 14px;
        margin-right: 5px;
        a {
            font-family: "Mallory-Book", sans-serif;
            font-size: 17px;
            text-decoration: none;
            color: $black;
        }
    }
    ul li:last-child {
        background: none;
    }

}

body.node-type-content-homepage.showtriangle,
body.node-type-content-region.showtriangle {
    background-image:url('../images/grey_green_triangle.png');
    background-repeat: no-repeat;
    background-size: auto;

}

body.node-type-content-region.showtriangle {
    background-position: center top -25px;
}

body.node-type-content-homepage.showtriangle {
    background-position: center top 120px;
}

.node-component-slideshow-with-text {
    h3 {
        font-family: "YaleNew-Roman", serif;
        font-size: 56px;
        line-height: 68px;
        color: $yale-blue;
        padding-top: 20px;
    }
    .field-name-body {
        font-family: "Mallory-Book", sans-serif;
        font-size: 23px;
        line-height: 38px;
        color: $yale-blue;
    }

        .content .right {
            position: relative;
            .topcorner {
                position: absolute;
                left: 0px;
                top: 0px;
                background-image: url('../images/top_left_triangle_image_bracket.png');
                background-size: 50px 50px;
                height:50px;
                width: 50px;
                z-index: 1;
            }
            .bottomcorner {
                position: absolute;
                right: 0px;
                bottom: 0px;
                background-image: url('../images/bottom_right_triangle_image_bracket.png');
                background-size: 50px 50px;
                height:50px;
                width: 50px;
                z-index: 1;
            }

        }


    .slick-initialized {
        button {
            z-index: 100;
            background-repeat: no-repeat;
            background-position: center center;
            height: 42px;
            width: 42px;

        }
        button.slick-prev {
            left: 15px;
            background-image: url('../images/slick_arrow_left.png');
        }
        button.slick-prev:hover {
            background-image: url('../images/slick_arrow_left_hover.png');
        }
        button.slick-prev:before {
            content:'';
        }
        button.slick-next {
            right: 15px;
            background-image: url('../images/slick_arrow_right.png');
        }
        button.slick-next:hover {
            background-image: url('../images/slick_arrow_right_hover.png');
        }
        button.slick-next:before {
            content:'';
        }

        .slick-dots li {
            button {
                background-image:url('../images/slick_slide_dot_off.png');
            }
            button:before {
                content: '';
            }
        }

        .slick-dots li.slick-active {
            button {
                background-image:url('../images/slick_slide_dot_on.png');
            }
        }
    }

}


.yatw-4-rows-section-second {
    div.node-component-slideshow-with-text {
        background-image: url('../images/component_slideshow_background.png');
        background-repeat: no-repeat;
        background-position: left -200px top 10px;
    }
}

body.showtriangle  {
    div.node-component-slideshow-with-text {
        background: none;
    }
}


.node-component-inline-slideshow {
    margin-bottom: 20px;
    max-width: 1440px;
    width: 100%;
    margin: auto;

    .content {
        margin-bottom: 30px;
        max-width: 1000px;
        width: 100%;
    }

    .slideshowimages {
        max-width: 790px;
        width: 100%;
        margin-right: 50px;
    }

    .inlineslideshowcaption {
        background-image: url('../images/news_orange_line.png');
        background-position: top left;
        background-repeat: no-repeat;
        padding-top: 20px;
        align-self: flex-end;
        margin-bottom: 100px;
        font-family: "Mallory-Book", sans-serif;
        font-size: 12px;
        line-height: 18px;
        color: $yale-med-grey;
    }

        .content .right {
            position: relative;
            .topcorner {
                position: absolute;
                left: 0px;
                top: 0px;
                background-image: url('../images/top_left_triangle_image_bracket.png');
                background-size: 50px 50px;
                height:50px;
                width: 50px;
                z-index: 1;
            }
            .bottomcorner {
                position: absolute;
                right: 0px;
                bottom: 0px;
                background-image: url('../images/bottom_right_triangle_image_bracket.png');
                background-size: 50px 50px;
                height:50px;
                width: 50px;
                z-index: 1;
            }

        }


    .slick-initialized {
        button {
            z-index: 100;
            background-repeat: no-repeat;
            background-position: center center;
            height: 42px;
            width: 42px;

        }
        button.slick-prev {
            left: 15px;
            background-image: url('../images/slick_arrow_left.png');
        }
        button.slick-prev:hover {
            background-image: url('../images/slick_arrow_left_hover.png');
        }
        button.slick-prev:before {
            content:'';
        }
        button.slick-next {
            right: 15px;
            background-image: url('../images/slick_arrow_right.png');
        }
        button.slick-next:hover {
            background-image: url('../images/slick_arrow_right_hover.png');
        }
        button.slick-next:before {
            content:'';
        }

        .slick-dots li {
            button {
                background-image:url('../images/slick_slide_dot_off.png');
            }
            button:before {
                content: '';
            }
        }

        .slick-dots li.slick-active {
            button {
                background-image:url('../images/slick_slide_dot_on.png');
            }
        }
    }

}


.regionmap {
    // -webkit-box-shadow: 0 1px 4px 2px rgba(50,50,50,.5);
    // box-shadow: 0 1px 4px 2px rgba(50,50,50,.5);
    color: #fff;
    background-position: center top;
    background-repeat: no-repeat;
    background-color: $yale-blue;
    position: relative;
    h2 {
        color: #fff;
        font-family: "YaleNew-Roman",serif;
        margin-top: 60px;
    }
}

body.logged-in {
    .regiontitle {
        top: 0px;
    }
}

.regionmapcontent {
    .regiontitle {
        -webkit-justify-content: space-between;
        justify-content: space-between;
        position: relative;
        top: -50px;
        .moreregionlink {
            a {
                color: #fff;
                font-family: "Mallory-Book", sans-serif;
                font-size: 18px;
                line-height: 30px;
                text-decoration: none;
                background-image: url('../images/regions_more_icon.png');
                background-repeat: no-repeat;
                background-position: right center;
                padding: 20px 60px 20px 0px;
            }
        }

    }
    .regiondataitems {
        border-top: 2px solid rgba(255,255,255,.6);
        max-width: 720px;
        width: 100%;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        figure {
            width: 240px;
            padding: 20px 0px;
        }
    }
    .regiondatasettitle {
        font-family: "Mallory-Book", sans-serif;
        font-size: 18px;
        line-height: 30px;
    }
    .regiondataitem > div {
        font-family: "YaleNew-Roman", serif;
        font-size: 38px;
        line-height: 42px;
        margin-bottom: 8px;
    }
    .regiondataitem figcaption {
        font-family: "Mallory-Book", sans-serif;
        font-size: 12px;
        line-height: 12px;
        letter-spacing: .5px;
        text-transform: uppercase;
        opacity: .75;
    }
}

.greycontentborder {
    border: 6px #ddd solid;
    background-color: #fff;
    figure.newsimage {
      .first {
        font-family: "Mallory-Book", sans-serif;
        font-size: 13px;
        line-height: 21px;
        color: $yale-grey;
      }
      .second {
        font-family: "Mallory-Book", sans-serif;
        font-size: 11px;
        line-height: 21px;
        color: $yale-grey;
      }
    }
    figure.verticalimage {
      .first {
        font-family: "Mallory-Book", sans-serif;
        font-size: 13px;
        line-height: 21px;
        color: $yale-grey;
      }
      .second {
        font-family: "Mallory-Book", sans-serif;
        font-size: 11px;
        line-height: 21px;
        color: $yale-grey;
      }
    }
}

body.page-search-site {
    #block-system-main {
        > .content {
            border: 6px #ddd solid;
            background-color: #fff;
            position: relative;
        }
    }
}

.node-component-cta-with-image {
  color: #fff;
  .content {
    p {
      font-family: "Mallory-Book", sans-serif;
      font-size: 18px;
      line-height: 30px;
    }
    .second {
      width: 100%;
      height: 100%;
      .cta_image_bg {
        background-size: cover;
        background-position: center center;
        height: 400px;
      }
    }
  }
  .content.bg-blue {
    background-color: $yale-blue;
    a {
        color: #fff;
    }
    a:hover {
        color: $yale-blue;
    }
  }

  .content.bg-green {
    background-color: $yale-green;
    a {
      color: #fff;
    }
    a:hover {
      color: $yale-grey;
    }
  }   

  .content.bg-beige {
    background-color: $yale-beige;
    color: $yale-blue;
    a {
      color: $yale-blue;
    }
    a:hover {
      color: $yale-grey;
    }
  }

  .content.bg-grey {
    background-color: $yale-grey;
    a {
        color: #fff;
    }
    a:hover {
        color: $yale-grey;
    }
  }

}

div.node-component-two-wide-cta-boxes.blueboxes > .content.flex > div {
    background-color: $yale-blue;
    color: #fff;
    padding: 50px 75px;
    h3 {
        font-family: "YaleNew-Roman", serif;
        font-size: 38px;
    }
    p,
    ul,
    li {
        font-family: "Mallory-Book", sans-serif;
        font-size: 18px;
        line-height: 30px;
    }
    a {
        color: #fff;
    }
    a:hover {
        color: $yale-blue;
    }

    .top {
        a {
            color: $yale-white;
        }
        a:hover {
            color: $yale-white;
        }
        strong {
            font-family:"Mallory-Bold";
            font-weight: bold;
        }
        em {
            font-family:"Mallory-BookItalic";
            font-style: normal;
        }
        strong em,
        em strong {
            font-family:"Mallory-BoldItalic";
            font-style: normal;
        }
        img {
            max-width: 300px;
            width: 100%;
            height: auto;
        }
    }

    ul {
        margin-left: 20px;
    }
}

div.node-component-two-wide-cta-boxes.greyboxes > .content.flex > div {
    background-color: $yale-grey;
    color: #fff;
    padding: 50px 75px;
    h3 {
        font-family: "YaleNew-Roman", serif;
        font-size: 38px;
    }
    p {
        font-family: "Mallory-Book", sans-serif;
        font-size: 18px;
        line-height: 30px;
    }
    a {
        color: #fff;
    }
    a:hover {
        color: $yale-grey;
    }
    img {
        max-width: 300px;
        width: 100%;
        height: auto;
    }
}

div.node-component-two-wide-cta-boxes > .content.flex > div.flex {
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: space-between;
    justify-content: space-between;
}

div.node-component-three-wide-arrow-cta h3 {
    font-family: "YaleNew-Roman", serif;
}

div.node-component-three-wide-arrow-cta p {
    font-family: "Mallory-Book", sans-serif;
    font-size: 18px;
    line-height: 30px;
    color: $yale-grey;
}

div.node-component-three-wide-arrow-cta a {
    font-family: "Mallory-Book", sans-serif;
    font-size: 17px;
    line-height: 22px;
    text-decoration-style: none;
    text-decoration-color: none;
    text-decoration: none;
    color: $yale-blue;
}

div.node-component-three-wide-arrow-cta .flex > div {

    > h3 {
        margin: 30px;
    }

    > p {
        margin: 30px;
    }

    max-width: auto;
    width: 100%;

    -webkit-flex-direction: column;
    flex-direction: column;

    > div {
        background-image: none;
    }

    .arrowlink.flex {
        -webkit-flex-direction: row;
        flex-direction: row;
    }
    .arrowlink a.flex {
        -webkit-justify-content: space-between;
        justify-content: space-between;
        -webkit-align-items: center;
        align-items: center;
        padding: 0px 30px;
        margin: 30px 0px;
        > span {
            max-width: 275px;
        }
    }



    a {
        div.infarrow {
            min-width: 68px;
            width: 68px;
            height: 68px;
            overflow: hidden;
            content:"";
            background: no-repeat center center rgba(0, 53, 107, 0.35);
            position: relative;
            border-radius: 50%;
            transition: background-color 250ms cubic-bezier(.44,0,0,1);

            span.arrowlinkinner {
                position: relative;
                display: block;
                width: 32px;
                height: 32px;
                &:before {
                    background-image: url("../images/deep_blue_arrow_right.svg");
                    background-repeat: no-repeat;
                    background-size: 32px 32px;
                    width: 32px;
                    height: 32px;
                    content: "";
                    display: block;
                    position: absolute;
                    left: 18px;
                    top: 19px;
                    opacity: 1;
                }
                &:after {
                    background-image: url("../images/white_arrow_right.svg");
                    background-repeat: no-repeat;
                    background-size: 32px 32px;
                    width: 32px;
                    height: 32px;
                    content: "";
                    display: block;
                    position: absolute;
                    left: -34px;
                    top: 19px;
                    opacity: 0;
                }

            }

        }

    }

    a:hover {
        div.infarrow {
            span.arrowlinkinner {
                &:before {
                    transform: translateX(40px);
                    transition: all 250ms ease-in-out;
                    opacity: 0;
                }
                &:after {
                    transform: translateX(52px);
                    transition: all 250ms ease-in-out;
                    opacity: 1;
                }
            }
        }
        div.infarrow {
            background: no-repeat center center rgba(0, 53, 107, 1);
        }
    }

}

div.node-component-three-wide-arrow-cta .content.flex > div.flex {
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    .top {
        background-color: $yale-white;
        height: 100%;
        margin: 0px;
    }
    .bottom {
        background-color: $yale-white;
        -webkit-clip-path:  polygon(0% 0%, 100% 0, 100% 70%, 90% 100%, 0% 100%);
    clip-path:          polygon(0% 0%, 100% 0, 100% 70%, 90% 100%, 0% 100%);
        margin: 0px;
    }
}

div.node-component-three-wide-arrow-cta {
    .content {
        //background-image: url('../images/3up_bg_lines.png');
        background-image: url('../images/3up_blue_lines.png');
        background-repeat: no-repeat;
        background-position: bottom right;
        padding-bottom: 40px;
    }
}

div.node-component-three-wide-arrow-cta .content.flex > div:first-child {
    margin-right: 50px;
}

div.node-component-three-wide-arrow-cta .content.flex > div:last-child {
    margin-left: 50px;
}

// body.not-front div.node-component-news-list-by-term {
//     background-image: none;
// }

div.node-component-news-list-by-term {
    background-image: url('../images/news_component_background.png');
    background-repeat: no-repeat;
    background-position: bottom right;
    margin-bottom: 20px;
    background-size: 1105px 1588px;

    div.arrowlink {
      max-width: 1180px;
    }

    div.noarrowlink {
        height: 128px;
    }

    div.arrowlink {
      a.flex {
          -webkit-justify-content: flex-end;
          justify-content: flex-end;
          -webkit-align-items: center;
          align-items: center;
          padding: 0px 30px;
          margin: 30px 0px;
          > span {
              max-width: 275px;
              margin-right: 20px;
          }
      }
      a {
          font-family: "Mallory-Book", sans-serif;
          font-size: 17px;
          line-height: 22px;
          text-decoration-style: none;
          text-decoration-color: none;
          text-decoration: none;
          color: $yale-blue;
          div.infarrow {
              min-width: 68px;
              width: 68px;
              height: 68px;
              overflow: hidden;
              content:"";
              background: no-repeat center center rgba(0, 53, 107, 0.35);
              position: relative;
              border-radius: 50%;
              transition: background-color 250ms cubic-bezier(.44,0,0,1);

              span.arrowlinkinner {
                  position: relative;
                  display: block;
                  width: 32px;
                  height: 32px;
                  &:before {
                      background-image: url("../images/deep_blue_arrow_right.svg");
                      background-repeat: no-repeat;
                      background-size: 32px 32px;
                      width: 32px;
                      height: 32px;
                      content: "";
                      display: block;
                      position: absolute;
                      left: 18px;
                      top: 19px;
                      opacity: 1;
                  }
                  &:after {
                      background-image: url("../images/white_arrow_right.svg");
                      background-repeat: no-repeat;
                      background-size: 32px 32px;
                      width: 32px;
                      height: 32px;
                      content: "";
                      display: block;
                      position: absolute;
                      left: -34px;
                      top: 19px;
                      opacity: 0;
                  }

              }

          }

      }

      a:hover {
          div.infarrow {
              span.arrowlinkinner {
                  &:before {
                      transform: translateX(40px);
                      transition: all 250ms ease-in-out;
                      opacity: 0;
                  }
                  &:after {
                      transform: translateX(52px);
                      transition: all 250ms ease-in-out;
                      opacity: 1;
                  }
              }
          }
          div.infarrow {
              background: no-repeat center center rgba(0, 53, 107, 1);
          }
      }
    }



}

div.node-component-news-list-by-term h3 {
    color: $yale-blue;
    margin-left: 120px;
    font-size: 56px;
    line-height: 68px;
}

div.node-component-news-list-by-term {
    .flex {
        margin-bottom: 20px;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        .optionallink {
            padding-top: 40px;
            .field-name-field-link {
                a {
                    font-family: "Mallory-Medium", sans-serif;
                    font-size: 17px;
                    line-height: 22px;
                    color: $yale-grey;
                    text-decoration: none;
                }
            }
        }
    }
}

div.node-component-news-list-by-term {
    > .content > .flex {
        max-width: 1160px;
        width: 100%;
    }
}

.view-related-news-by-term h4 a {
    text-decoration: none;
}

div.node-component-news-list-by-term .view-content {
    background-color: #fff;
    width: 1220px;
    padding-right: 40px;
    padding-bottom: 40px;
    margin-top: 40px;
    -webkit-box-shadow: 1px 1px 1px 0px rgba(0,0,0,0.36);
    -moz-box-shadow:    1px 1px 1px 0px rgba(0,0,0,0.36);
    box-shadow:         1px 1px 1px 0px rgba(0,0,0,0.36);



    .optionallink {
        padding-top: 40px;
        a {
            font-family: "Mallory-Medium", sans-serif;
            font-size: 17px;
            line-height: 22px;
            color: $yale-grey;
            text-decoration: none;
        }
    }

    .flex {
        margin-bottom: 20px;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        .optionallink {
            padding-top: 40px;
            .field-name-field-link {
                a {
                    font-family: "Mallory-Medium", sans-serif;
                    font-size: 17px;
                    line-height: 22px;
                    color: $yale-grey;
                    text-decoration: none;
                }
            }
        }
    }

    .node-content-news {
        .content {
            -webkit-justify-content: flex-start;
            justify-content: flex-start;
            .left {
                max-width: 320px;
                width: 100%;
                margin-right: 20px;
            }
            .right {
                .newsdate {
                    font-family: "Mallory-Book", sans-serif;
                    color: $yale-grey;
                    font-size: 14px;
                }
                h3 {
                    margin-left: 0px;
                    a {
                        text-decoration: none;
                    }
                    font-family: "YaleNew-Roman", serif;
                    font-size: 28px;
                    line-height: 34px;
                }
                .newsrowcontent {
                    font-family: "Mallory-Book", sans-serif;
                    font-size: 16px;
                    line-height: 24px;
                    color: $yale-grey;
                    padding-bottom: 20px;
                    background-image: url('../images/news_orange_line.png');
                    background-repeat: no-repeat;
                    background-position: bottom left;
                    a {
                        font-family: "Mallory-Book", sans-serif;
                        text-decoration: none;
                    }
                }
            }
        }
    }

}

div.view-related-news-by-term img {
    max-width: 315px;
    width: 100%;
    height: auto;
}



div.view-related-news-by-term .views-row {

    .views-field-title {
        margin-left: 335px;
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .views-field-title a {
        font-size: 28px;
        line-height: 34px;
        color: $yale-blue;
        text-decoration: none;
    }

    .views-field-body {
        font-family: "Mallory-Book", sans-serif;
        margin-left: 335px;
        font-size: 16px;
        line-height: 24px;
        color: $yale-grey;
        padding-bottom: 20px;
        background-image: url('../images/news_orange_line.png');
        background-repeat: no-repeat;
        background-position: bottom left;
    }

    .views-field-field-image {
        max-width: 315px;
        width: 100%;
        float: left;
    }

}

div.view-related-news-by-term .views-row {
    clear: both;
    margin-bottom: 40px;
    min-height: 220px;
}

div.view-related-news-by-term {
    .newsdate {
        padding-top: 20px;
    }
}

.view-all-news {

    .newsdate {
        font-family: "Mallory-Book", sans-serif;
        color: $yale-grey;
        font-size: 14px;
        padding-top: 20px;
    }

    div.node-content-news {
        margin-bottom: 20px;
        padding-right: 20px;
        .left {
            max-width: 315px;
            width: 100%;
            margin-right: 20px;
            img {
                max-width: 315px;
                width: 100%;

            }
        }
        .right {
            h3 {
                margin: 0px;
                padding-top: 10px;
                padding-bottom: 30px;
                font-family: "YaleNew-Roman", serif;
                font-size: 28px;
                line-height: 34px;
            }
            a {
                text-decoration: none;
            }
            .newsrowcontent {
                font-family: "Mallory-Book", sans-serif;
                font-size: 16px;
                line-height: 24px;
                color: $yale-grey;
                padding-bottom: 20px;
                background-image: url('../images/news_orange_line.png');
                background-repeat: no-repeat;
                background-position: bottom left;
            }
        }
    }

}

div.node-component-three-wide-cta-boxes .flex {

    > div.first,
    > div.second,
    > div.third {
        border-bottom: 1px solid #fff;
        border-right: 1px solid #fff;
        background-color: $yale-blue;
        padding: 15px 20px;
        color: #fff;
        width: 33%;
        height: 187px;
        background-image: url('../images/cta_white_arrow.svg');
        background-position: bottom 20px right 20px;
        background-repeat: no-repeat;
        a {
            color: #fff;
            text-decoration: none;
        }
    }

    > div.flex {
        -webkit-flex-direction: column;
        flex-direction: column;
        -webkit-justify-content: space-between;
        justify-content: space-between;
    }

    > div:last-child {
        border-right: none;
    }

    h3 {
        font-size: 18px;
        line-height: 16px;
        font-family: "Mallory-bold", sans-serif;
    }

    p {
        font-family: "Mallory-book", sans-serif;
    }

}

div.node-component-video-player-banner {

    .content {
        position: relative;
        min-height: 390px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        &:before { // gradient fun //
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-image: linear-gradient(to right,rgba(0, 53, 107, 1) 20%,rgba(255, 255, 255, 0.3));
        }
    }

    h3 {
        font-family: "YaleNew-Roman", serif;
        color: #fff;
        padding: 40px 0px;
        position: relative;
    }

    .field-name-field-box-1-text {
        font-family: "Mallory-Book", sans-serif;
        font-weight:normal;
        font-style:normal;
        color: #fff;
        font-size: 23px;
        line-height: 38px;
        margin-bottom: 30px;
        max-width: 670px;
        position: relative;
    }

    .videoselectors {
        position:relative;

        a {
            text-decoration:none;
        }
        // #videolistopen {
        //     display:table;
        //     width: 315px;
        //     padding:5px;
        //     padding-left: 80px;
        //     font-family: "Mallory-Book", sans-serif;
        //     color: #fff;
        //     background-image:url('../images/video_select_globe_icon.svg');
        //     background-repeat: no-repeat;
        //     background-position: left center;
        //     height:70px;
        //     font-size: 17px;
        //     line-height: 22px;
        //     span {
        //         display: table-cell;
        //         vertical-align: middle;
        //     }
        // }

        #videolistopenouter {
            width: 315px;
            height: 70px;
          }

          #videolistopenicon {
            background-color: #fff;
            background-image: url('../images/blue_video_globe.svg');
            background-repeat: no-repeat;
            background-position: center center;
            width: 81px;
            height: 70px;
            -webkit-clip-path: polygon(0 40%, 40% 0, 100% 0, 100% 100%, 0% 100%);
            clip-path: polygon(0 25%, 30% 0, 100% 0, 100% 100%, 0% 100%);
          }

          #videolistopenwords {
            width: 100%;
            button {
                width: 100%;
                padding: 0px;
                margin: 0px;
                border: none;
                background: none;
                font-family: "Mallory-book", sans-serif;
                font-size: 17px;
                line-height: 22px;
                display: block;
                background-image: url('../images/video_triangle.svg');
                background-repeat: no-repeat;
                background-position: 225px 42px;
                transition: background-position 250ms cubic-bezier(.44,0,0,1);
                text-align: left;
                align-items: flex-start;
            }
            button:hover {
                cursor: pointer;
                background-position: 232px 50px;
            }

            span {
                font-family: "Mallory-book", sans-serif;
                color: #fff;
                line-height: 66px;
                padding-left: 25px;
                // width: 223px;
                height: 66px;
                display: block;
                background-image: url('../images/video_triangle.svg');
                background-repeat: no-repeat;
                background-position: 225px 42px;
                transition: background-position 250ms cubic-bezier(.44,0,0,1);
            }
            span:hover {
                cursor: pointer;
                background-position: 232px 50px;
            }
            span.red {
                color: red;
            }
          }

          #videolistopenwords {
            border: 2px solid #fff;
          }

        ul#videoselect {
            position:absolute;
            z-index: 1000;
            top:32px;
            width:315px;
            display:none;
            margin: 20px 0px 0px 20px;
            border:2px solid $yale-med-grey;
            -moz-border-radius: 10px;
            -webkit-border-radius: 10px;
            border-radius: 10px;
            -webkit-background-clip: padding-box;
            -moz-background-clip: padding;
            background-clip: padding-box;
        }

        ul#videoselect li {
            padding:5px 10px;
            list-style: none;
            -webkit-background-clip: padding-box;
            -moz-background-clip: padding;
            background-clip: padding-box;

            a {
                font-family: "Mallory-Book", sans-serif;
                color: $yale-grey;
                display: inline-block;
                width: 100%;
            }
            a:hover {
                color: $yale-med-blue;
            }

        }

        ul#videoselect li a:hover {
            background-image:url('../images/dropdownarrow_hover.png');
            background-repeat: no-repeat;
            background-position: right;
        }

        ul#videoselect li:first-child {
            border-top-left-radius:     10px;
            border-top-right-radius:    10px;

        }
        ul#videoselect li:last-child {
            border-bottom-right-radius: 10px;
            border-bottom-left-radius:  10px;
        }

        ul#videoselect li:nth-child(odd) {
            background-color: $yale-white;
        }
        ul#videoselect li:nth-child(even) {
            background-color: $yale-menu;
        }

    }


}

////////////////////////////////////////////////
// Resource List Accordion //
////////////////////////////////////////////////

.ui-accordion .ui-accordion-icons {
    padding: 0px;
}

.ui-accordion .ui-accordion-header {
    display: block;
    cursor: pointer;
    position: relative;
    margin-top: 0px;
    padding: 0px;
    min-height: 0px;
}

.ui-corner-all, .ui-corner-top, .ui-corner-right, .ui-corner-tr {
    border-top-right-radius: 0px;
}

.ui-state-default .ui-icon {
    background-image: none;
}

.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
    border: none;
    background: none;
    font-weight: normal;
    color: inherit;
}

.ui-widget-content {
    border: none;
    background: none;
    color: inherit;
}

.ui-state-default a, .ui-state-default a:link, .ui-state-default a:visited {
    color: inherit;
    text-decoration: none;
}

.ui-accordion .ui-accordion-content {
    padding: 0px;
}

.ui-corner-all, .ui-corner-bottom, .ui-corner-right, .ui-corner-br,
.ui-corner-all, .ui-corner-bottom, .ui-corner-left, .ui-corner-bl {
    border-bottom-left-radius: 0px;
}

.view-resource-list-filter-by-type,
.view-all-resources,
.view-resource-list {

    .ui-state-default a,
    .ui-state-default a:link,
    .ui-state-default a:visited {
        font-family: "YaleNew-Roman", serif;
        font-size: 28px;
        line-height: 34px;
    }

    h3 {
        border-bottom: 1px solid $yale-med-grey;
        padding-bottom: 10px;
        margin-bottom: 10px;
        width: 100%;
    }

    h3.ui-state-default {
        background-image: url('../images/blue_positive_accordion_icon.svg');
        background-position: right 20px center;
        background-repeat: no-repeat;
    }

    h3.ui-state-active {
        background-image: url('../images/red_negative_accordion_icon.svg');
        background-position: right 20px center;
        background-repeat: no-repeat;
    }

    .ui-accordion-content > div {
        margin: 10px 0px;
    }

    .ui-accordion-content a {
        font-family: "Mallory-Medium", sans-serif;
        font-size: 16px;
        line-height: 28px;
        color: $yale-blue;
        border-bottom: 2px solid $yale-blue;
        text-decoration: none;
        margin: 5px 0px;
    }

    .views-exposed-form {
        background-color: $yale-white;
        padding: 50px 30px;
        max-width: 910px;
        width: 100%;
        margin-bottom: 20px;
    }

    .views-exposed-form h3 {
        border: none;
        font-size: 38px;
        line-height: 42px;
        color: $yale-blue;
    }

    .views-exposed-form .views-exposed-widget input.form-text {
        font-family: "Mallory-Book", sans-serif;
        color: $yale-blue;
        font-size: 18px;
        padding: 17px;
        width: 100%;
    }

    .views-exposed-form {
        .views-widget-filter-combine { width: 80%; }
        .views-submit-button { width: 20%;
            input {
                background-image: url('../images/search_icon.svg');
                background-color: $yale-blue;
                height: 60px;
                width: 60px;
                background-repeat: no-repeat;
                background-position: center center;
                margin: 0px;
                text-indent: -9999px;
                border: none;
            }
        }
        input.form-text {
            -webkit-transition: all 0.30s ease-in-out;
            -moz-transition: all 0.30s ease-in-out;
            -ms-transition: all 0.30s ease-in-out;
            -o-transition: all 0.30s ease-in-out;
            outline: none;
            border: 1px solid #DDDDDD;
        }
        input.form-text:focus {
            box-shadow: 0 0 5px rgba(81, 203, 238, 1);
            border: 1px solid $yale-med-blue;
        }
        input.form-submit {
            transition: color 250ms cubic-bezier(0.44, 0, 0, 1);
        }
        input.form-submit:hover {
            background-color: #286DC0;
        }
    }

}

#search-api-page-search-form-solr-search-page--2,
.mobilesearch form.search-form,
.pageheader form.search-form {

    input.form-submit {
        display: block !important;
        background-image: url('../images/search_icon.svg');
        background-color: $yale-blue;
        height: 60px;
        width: 60px;
        background-repeat: no-repeat;
        background-position: center center;
        margin: 0px;
        text-indent: -9999px;
        border: none;
        transition: color 250ms cubic-bezier(.44,0,0,1);
    }

    input.form-submit:hover {
        background-color: $yale-med-blue;
    }

    .form-type-textfield {
        margin: 0px;
    }

    input.form-text,
    input.custom-search-box {
        font-family: "Mallory-Book", sans-serif;
        color: $yale-blue;
        font-size: 18px;
        padding: 17px;
        width: 100%;
        height: 60px;
    }

    input.custom-search-box {
        -webkit-transition: all 0.30s ease-in-out;
        -moz-transition: all 0.30s ease-in-out;
        -ms-transition: all 0.30s ease-in-out;
        -o-transition: all 0.30s ease-in-out;
        outline: none;
        border: 1px solid #DDDDDD;
    }

    input.custom-search-box::-webkit-input-placeholder { /* WebKit, Blink, Edge */
        color: $yale-blue;
    }
    input.custom-search-box:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color: $yale-blue;
        opacity:  1;
    }
    input.custom-search-box::-moz-placeholder { /* Mozilla Firefox 19+ */
        color: $yale-blue;
        opacity:  1;
    }
    input.custom-search-box:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: $yale-blue;
    }
    input.custom-search-box::-ms-input-placeholder { /* Microsoft Edge */
        color: $yale-blue;
    }

    input.custom-search-box::placeholder { /* Most modern browsers support this now. */
        color: $yale-blue;
    }


    input.custom-search-box:focus {
        box-shadow: 0 0 5px rgba(81, 203, 238, 1);
        border: 1px solid $yale-med-blue;
    }

    .container-inline {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        .center {
            justify-content: center;
            .center {
                align-items: center;
            }
        }
    }

}

.view-all-news {
    .views-exposed-form {
        background-color: #f9f9f9;
        padding: 50px 30px;
        max-width: 910px;
        width: 100%;
        margin-bottom: 20px;
        h3 {
            border: none;
            font-size: 38px;
            line-height: 42px;
            color: #00356B;
        }
    }

    .views-exposed-widget {

        input.form-submit {
            display: block !important;
            background-image: url('../images/search_icon.svg');
            background-color: $yale-blue;
            height: 60px;
            width: 60px;
            background-repeat: no-repeat;
            background-position: center center;
            margin: 0px;
            text-indent: -9999px;
            border: none;
            transition: color 250ms cubic-bezier(.44,0,0,1);
        }
    }
    .newsrowcontent {
        padding-right: 20px;
    }

    .views-exposed-widget input.form-submit {
        display: block !important;
        background-image: url('../images/search_icon.svg');
        background-color: $yale-blue;
        height: 60px;
        width: 60px;
        background-repeat: no-repeat;
        background-position: center center;
        margin: 0px;
        text-indent: -9999px;
        border: none;
        transition: color 250ms cubic-bezier(.44,0,0,1);
    }

    input.form-submit:hover {
        background-color: $yale-med-blue;
    }

    .form-type-textfield {
        margin: 0px;
    }

    #edit-body-value-wrapper {
        width: 80%;
    }

    input.form-text,
    input.custom-search-box {
        font-family: "Mallory-Book", sans-serif;
        color: $yale-blue;
        font-size: 18px;
        padding: 17px;
        width: 100%;
        height: 60px;
    }

    input.custom-search-box {
        -webkit-transition: all 0.30s ease-in-out;
        -moz-transition: all 0.30s ease-in-out;
        -ms-transition: all 0.30s ease-in-out;
        -o-transition: all 0.30s ease-in-out;
        outline: none;
        border: 1px solid #DDDDDD;
    }

    input.custom-search-box::-webkit-input-placeholder { /* WebKit, Blink, Edge */
        color: $yale-blue;
    }
    input.custom-search-box:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color: $yale-blue;
        opacity:  1;
    }
    input.custom-search-box::-moz-placeholder { /* Mozilla Firefox 19+ */
        color: $yale-blue;
        opacity:  1;
    }
    input.custom-search-box:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: $yale-blue;
    }
    input.custom-search-box::-ms-input-placeholder { /* Microsoft Edge */
        color: $yale-blue;
    }

    input.custom-search-box::placeholder { /* Most modern browsers support this now. */
        color: $yale-blue;
    }


    input.custom-search-box:focus {
        box-shadow: 0 0 5px rgba(81, 203, 238, 1);
        border: 1px solid $yale-med-blue;
    }

}

#search-api-page-search-form-solr-search-page--2 {
    > div {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        // input.form-text {}
        input.form-submit {
            margin-left: 10px;
        }
    }

}

body.page-search #block-system-main .content h1 {
    background-color: $yale-white;
    font-size: 38px;
    line-height: 42px;
    color: $yale-blue;
    max-width: 900px;
    padding-left: 40px;
    padding-top: 40px;
}

.search-api-page-results {
    margin-bottom: 40px;
    h2 {
        color: $yale-blue;
    }
    ul {
        margin-left: 40px;
    }
    .searchbody,
    .search-performance {
        font-family: "Mallory-Book", sans-serif;
        margin: 10px 0px;
        color: $yale-accessable-brown;
    }
    .url a {
        font-family: "Mallory-Book", sans-serif;
        color: $yale-accessable-brown;
        font-size: 14px;
        line-height: 22px;
    }
    li.search-result {
        background-image: url('../images/news_orange_line.png');
        background-position: bottom left;
        background-repeat: no-repeat;
        padding-bottom: 20px;
        margin-bottom: 20px;
        h3.title {
            a {
                text-decoration: none;
                font-size: 28px;
                line-height: 34px;
            }
        }
    }
}

.search-results .title {
    font-size: 28px;
    line-height: 34px;
}

#search-api-page-search-form {
    padding: 40px;
    padding-top: 0px;
    background-color: $yale-white;
    max-width: 900px;
    width: 100%;
    > div {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        width: 100%;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        > div.form-item {
            margin: 0px;
            width: 100%;
        }
        input.form-submit {
            display: block !important;
            background-image: url('../images/search_icon.svg');
            background-color: $yale-blue;
            height: 60px;
            width: 60px;
            background-repeat: no-repeat;
            background-position: center center;
            margin: 0px;
            text-indent: -9999px;
            border: none;
            margin-left: 10px;
        }
        input.form-text {
            font-family: "Mallory-Book", sans-serif;
            color: $yale-blue;
            font-size: 18px;
            padding: 17px;
            width: 100%;
            height: 60px;
        }
    }
}

form.search-form {
    input.form-actions {
        margin-top: 0px;
        margin-bottom: 0px;
    }
    > div {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        width: 100%;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        > div.form-item {
            margin: 0px;
            width: 100%;
        }
        input.form-actions,
        input.form-submit {
            display: block !important;
            background-image: url('../images/search_icon.svg');
            background-color: $yale-blue;
            height: 60px;
            width: 60px;
            background-repeat: no-repeat;
            background-position: center center;
            margin: 0px;
            text-indent: -9999px;
            border: none;
            margin-left: 10px;
        }
        input.form-text {
            font-family: "Mallory-Book", sans-serif;
            color: $yale-blue;
            font-size: 18px;
            padding: 17px;
            width: 100%;
            height: 60px;
        }
    }
}



.mobilesearch form.search-form {
    input.custom-search-box {
        border: 1px solid $yale-menu;
        background-color: $yale-white;
    }
    .form-type-textfield {
        width: 100%;
        margin: 0px 10px;
    }
    input.form-submit {
        margin-right: 10px;
    }
}

.mobilesearch .left a,
.pageheadersearch .left a {
    font-family: "Mallory-Book", sans-serif;
    font-size: 18px;
    color: $yale-blue;
    text-decoration: none;
    margin-right: 20px;
    line-height: 60px;
}

.pageheadersearch .left a:hover {
    color: $yale-med-blue;
}

.page-search-site {
    main { margin-bottom: 40px; }
    h2 { color: $yale-blue; }
    h3 { color: $yale-blue; }

    .spelling-suggestions {
        dl.form-item {
            dt {
                font-family: "Mallory-Book", sans-serif;
                font-size: 16px;
                line-height: 24px;
                color: $yale-grey;
            }
        }
        dd {
            a {
                font-family: "Mallory-Book", sans-serif;
                font-size: 16px;
                line-height: 24px;
                color: $yale-grey;
            }
        }
    }

    .content {
        ul {
            max-width: 1390px;
            width: 100%;
            margin: auto;
            li {
                font-family: "Mallory-Book", sans-serif;
                font-size: 16px;
                line-height: 24px;
                color: $yale-grey;
            }
        }
    }

    .ds-search-extra {
        font-family: "Mallory-Book", sans-serif;
        font-size: 16px;
        line-height: 24px;
        color: $yale-grey;
    }

    .searchresult {
        .searchresulturl {
            a {
                font-family: "Mallory-Book", sans-serif;
                font-size: 14px;
                line-height: 22px;
                color: $yale-accessable-brown;
                background-image: url('../images/news_orange_line.png');
                background-position: bottom left;
                background-repeat: no-repeat;
                padding-bottom: 20px;
                margin-bottom: 20px;
            }
        }
    }

    .node-content-news {
        .newssearchbody {
            font-family: "Mallory-Book", sans-serif;
            font-size: 16px;
            line-height: 24px;
            color: $yale-grey;
        }
    }
    .searchresult {
        .profilesearchbody {
            font-family: "Mallory-Book", sans-serif;
            font-size: 16px;
            line-height: 24px;
            color: $yale-grey;
        }
    }

}

#defaultmap {
    opacity: 1;
    transition: opacity 250ms cubic-bezier(.44,0,0,1);
}

#defaultmap.fadedefaultmap {
    opacity: 0;
}

.worldmapitems {
    height: 670px;
    z-index: 10;
    position: relative;
    .worldmapitem,
    .defaultworldmapitem {
        position: absolute;
        width: 960px;
        right: 40px;
        top: 0px;
        img {
            width: 960px;
            height: auto;
        }
    }
    .worldmapitem {
        //display: none;
        opacity: 0;
        transition: opacity 250ms cubic-bezier(.44,0,0,1);
    }
    .worldmapitem.showworldmapitem {
        opacity: 1;
    }
}

.worldmapnames {
    top: 455px;
    position: relative;
    z-index: 100;
    margin-left: 120px;
    > div {
        margin-bottom: 2px;
        margin-right: 2px;
    }
    > div a {
        display: block;
        padding: 20px 20px;
        background-color: $yale-blue;
        background-image: url('../images/forward_white_arrow.svg');
        background-position: right 20px center;
        background-repeat: no-repeat;
        color: #fff;
        text-decoration: none;
        font-family: "Mallory-Book", sans-serif;
        font-size: 16px;
        line-height: 16px;
    }
    > div.tableauicon a {
        background-image: url('../images/globe_icon.svg');
        background-color: $yale-grey;
        background-position: right 15px center;
    }
    > div a:hover {
        background-color: $yale-med-blue;
    }
}

#hometitle {
    h2 {
        position: absolute;
        margin-left: 120px;
        top: 100px;
        font-size: 76px;
        line-height: 91px;
        font-family: "YaleNew-Roman", serif;
        color: $yale-blue;
        max-width: 600px;
        width: 100%;
        z-index: 1001;
    }
    .regionsbutton {
        position: absolute;
        top: 220px;
        right: 80px;
        z-index: 1000;
        .regionsbuttonlink {
            border: none;
            background: none;
            background-color: $yale-green;
            font-family: "Mallory-Book", sans-serif;
            background-image: url('../images/trans_arrow.svg');
            background-repeat: no-repeat;
            background-position: right 10px center;
            height: 72px;
            width: 268px;
            font-size: 17px;
            line-height: 72px;
            text-align: left;
            padding-left: 20px;
            color: #fff;
        }
        a {
            color: #fff;
            text-decoration: none;
            display: block;
        }
    }
}

.node-content-profile {
    margin-top: 20px;
}

.node-content-profile,
.node-component-profile-list {

    h2,
    h3 {
        color: $yale-blue;
        margin: 20px 0px;
    }

    .profilenode {

        border-bottom: 4px solid #ddd;
        margin-bottom: 30px;
        padding-bottom: 30px;
        padding-right: 10px;

        h4,
        h2 {
            color: $yale-blue;
        }

        h2 {
            margin: 0px;
        }

        .profilerank {
            font-family: "Mallory-Book", sans-serif;
            font-size: 15px;
            line-height: 30px;
            color: $yale-accessable-brown;
        }

        .left {
            margin-right: 50px;
        }

        .profilebody {
            font-family: "Mallory-Book", sans-serif;
            font-size: 18px;
            line-height: 30px;
            color: $yale-grey;
        }

    }

    div.profilenode .flex .right {
        width: 100%;
        .profileemail {
            margin-top: 5px;
        }
    }

    div.profilenode:last-child {
        border-bottom: none;
    }

}

div.sitealert {
    background: $yale-red;
}

div.site-alert div.info,
div.site-alert div.low,
div.site-alert div.high,
div.sitealert > div {
    border: none;
    color: #fff;
    background-image: url('../images/alert_corner.png');
    background-repeat: no-repeat;
    background-size: auto;
    background-position: right bottom;
        .flex {
            .left {
                font-family: "Mallory-Bold", sans-serif;
                font-size: 16px;
                line-height: 30px;
            }
            .right {
                font-family: "Mallory-Book", sans-serif;
                font-size: 16px;
                line-height: 30px;
            }
        }
}

.bean-stay-connected-block {
    background-color: $yale-green;
    max-width: 1390px;
    width: 100%;
    margin: auto;
    position: relative;
    .content {
        padding: 0px 120px;
        padding-top: 60px;
        padding-bottom: 40px;
    }

    .left  {
        margin-right: 40px;
        max-width: 675px;
        width: 100%;
    }
    .right {
        padding-top: 60px;
        div.linkbutton {
            margin-bottom: 20px;
        }
    }

    h3 {
        color: #fff;
    }
    .stayconnectedbody p {
        color: #fff;
        font-family: "Mallory-Book", sans-serif;
        font-size: 23px;
        line-height: 38px;
    }
    div.linkbutton a {
        color: #fff;
    }
    div.linkbutton a:hover {
        color: $yale-green;
    }
}

body.node-type-content-news {
    .stayconnectedblock {
        margin-top: -60px;
    }
    .externalnews {
        font-family: "Mallory-Book", sans-serif;
    }
    // footer#footer {}
}

.node-content-resource {
    h2 {
        color: $yale-blue;
    }
    font-family: "Mallory-Book", sans-serif;
    div {
        margin-bottom: 5px;
    }
    label {
        font-family: "Mallory-Book", sans-serif;
        font-weight: bold;
    }
}

.view-social-slider-content {
    .views-row {
        .socialitem {
            height: 315px;
            width: 315px;
            background-size: auto 315px;
            background-repeat: no-repeat;
            background-position: center center;
        }
    }
    color: #fff;
    a {
        color: #fff;
    }
    .blue-0 {
        background-color: $yale-med-blue;
    }
    .blue-1 {
        background-color: $yale-light-blue
    }
    .content {
        width: 315px;
        height: 315px;
    }
}

.node-component-inline-callout-box {
    .content.flex {
        background-color: $yale-white;

        h3 {
            color: $yale-blue;
        }

        font-family: "Mallory-Book", sans-serif;
        color: $yale-grey;

        a {
            color: $yale-med-blue;
        }

    }
}

body.views-ysm_people_faculty_landing {
    h2.viewtitle {
        color: $yale-blue;
        margin-bottom: 20px;
    }
    .view-content {
        max-width: 1440px;
        width: 100%;
        margin: 0 auto;
        padding-left: 25px;
        padding-right: 25px;
    }
    .views-field {
        li {
            font-family: "Mallory-Book", sans-serif;
            color: $yale-grey;
        }
    }
    .views-label {
        font-family: "YaleNew-Bold", serif;
        font-size: 18px;
        line-height: 24px;
        font-weight: bold;
    }
}

body.views-ysm_people_faculty_database {
    h2.viewtitle {
        color: $yale-blue;
        margin-bottom: 20px;
    }
}

.view-ysm-people-faculty-database {

    .pager {
        margin-top: 20px;
        li {
            color: $yale-grey;
            font-family: "Mallory-Book", sans-serif;
        }
        a {
            color: $yale-blue;
            font-family: "Mallory-Book", sans-serif;
        }
    }

    .views-exposed-widgets {
        select#edit-country {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;       /* remove default arrow */
            border: 1px solid $yale-grey;
            border-radius: 0px;
            background: none;
            background-color: $yale-white;
            font-size: 17px;
            color: $yale-grey;
            font-family: "Mallory-Book", sans-serif;
            padding: 10px;
            background-image: url('../images/select_arrow_off.svg');
            background-position: right 10px center;
            background-repeat: no-repeat;
            width: 300px;
            height: 60px;
            &:hover,&:active,&:focus {
                background-image: url('../images/select_arrow_on.svg');
                border: 1px solid $yale-med-blue;
                color: $yale-med-blue;
            }
        }
        .form-type-textfield {
            input {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;       /* remove default arrow */
                border: 1px solid $yale-grey;
                border-radius: 0px;
                background: none;
                background-color: $yale-white;
                font-size: 17px;
                color: $yale-grey;
                font-family: "Mallory-Book", sans-serif;
                padding: 10px;
                height: 60px;
            }
        }
        .views-submit-button {
            input#edit-submit-ysm-people-faculty-database {
                background-image: url('../images/search_icon.svg');
                background-color: $yale-blue;
                height: 60px;
                width: 60px;
                background-repeat: no-repeat;
                background-position: center center;
                margin: 0px;
                text-indent: -9999px;
                border: none;
            }
        }
    }

    .view-header,
    .views-row {
        a {
            font-family: "Mallory-Book", sans-serif;
        }
    }

    .views-row {
        .views-field-titles {
            font-family: "YaleNew-Roman", serif;
        }
    }

}

body.node-type-webform {
    article {
        h2 {
            color: $yale-blue;
        }
        form {
            .description {
                font-family: "Mallory-Book", sans-serif;
                font-size: 14px;
                line-height: 22px;
                color: $yale-grey;
                padding-left: 15px;
            }
            label {
                font-family: "Mallory-Medium", sans-serif;
                font-size: 16px;
                padding-bottom: 0px;
                padding-left: 15px;
                color: $yale-blue;
            }

            input.form-text {
                background-color: $yale-white;
                border: 1px solid $yale-menu;
                font-family: "Mallory-Medium", sans-serif;
                font-size: 16px;
                color: $yale-grey;
                line-height: 38px;
                padding: 5px 15px;
                &:hover,
                &:focus,
                &:active {
                    border: 1px solid $yale-med-blue;
                    color: $yale-med-blue;
                    box-shadow: 0 0 5px $yale-blue-hover;
                }
            }

            input {
                max-width: 600px;
                width: 100%;
                margin-top: 10px;
            }

            .webform-component-checkboxes,
            .webform-component-radios {
                .form-checkboxes,
                .form-radios {
                    margin-left: 20px;
                    input {
                        width: auto;
                    }
                }
            }

            select {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;       /* remove default arrow */
                border: 1px solid $yale-grey;
                border-radius: 0px;
                background: none;
                background-color: $yale-white;
                font-size: 17px;
                color: $yale-grey;
                font-family: "Mallory-Book", sans-serif;
                font-size: 16px;
                line-height: 38px;
                padding: 5px 15px;
                margin-top: 10px;
                background-image: url('../images/select_arrow_off.svg');
                background-position: right 10px center;
                background-repeat: no-repeat;
                max-width: 600px;
                width: 100%;
                &:hover,&:active,&:focus {
                    background-image: url('../images/select_arrow_on.svg');
                    border: 1px solid $yale-med-blue;
                    color: $yale-med-blue;
                }
            }

            textarea {
                padding: 10px;
                font-family: "Mallory-Book", sans-serif;
                font-size: 16px;
                line-height: 20px;
                border: 1px solid $yale-grey;
                &:hover,&:active,&:focus {
                    background-image: none;
                    background-color: #fff;
                    border: 1px solid $yale-med-blue;
                    color: $yale-med-blue;
                }
            }

            input.form-submit {
                border: 1px solid $yale-grey;
                background-color: $yale-white;
                font-size: 17px;
                color: $yale-grey;
                font-family: "Mallory-Book", sans-serif;
                line-height: 38px;
                padding: 10px 20px;
                width: 125px;
                height: auto;
                border-radius: 5px;
                &:hover {
                    background-image: none;
                    background-color: #fff;
                    border: 1px solid $yale-med-blue;
                    color: $yale-med-blue;
                }
                &:focus,&:active {
                    background-color: $yale-white;
                }
            }

        }
    }
}


footer#footer {
    background-color: $yale-black;
    color: #fff;

    .flex {
        justify-content: space-between;
    }


    a {
        color: #fff;
    }
    a:hover {
        opacity: .5;
    }

    ul, li {
        list-style: none;
        list-style-type: none;
        margin: 0px;
        padding: 0px;
        display: inline;
    }

    li {
        img {
            height: 32px;
            width: 32px;
            margin: 0px 0px 0px 20px;
        }
    }

    .firstcol {
        .right {
            div {
                font-family: "YaleNew-Roman", serif;
                font-size: 16px;
                line-height: 19px;
            }
            div:last-child {
                font-family: "Mallory-Book", sans-serif;
                font-size: 15px;
                line-height: 22px;
                ul li {
                    a {
                        text-decoration: none;
                        margin-right: 5px;
                        transition: text-decoration 250ms cubic-bezier(.44,0,0,1);
                    }
                    > a:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

}

body.not-front div.regionsbutton {
    display: none;
    visibility: hidden;
}


.search-form {
    .form-item {
        label {
            font-family: "Mallory-Book", sans-serif;
            font-size: 15px;
            line-height: 22px;
            color: $yale-blue;
        }
    }
    #edit-submit {
        margin-top: 22px;
    }

}

.node-component-cta-with-image {
  .bg-blue {
    background-color: $yale-blue;
  }
  .bg-grey {
    background-color: $yale-grey;
  }
}

.newsfilters {
    padding: 10px;
    margin: 20px 0px;
    h4 {
        color: $yale-blue;
        font-size: 22px;
        line-height: 44px;
    }
  
    .form-item {
        margin-bottom: 5px;
        input {
            outline: none;
        }
        label {
            font-family: "Mallory-Book", sans-serif;
            font-size: 16px;
            line-height: 32px;
            color: $yale-grey;
            padding-left: 3px;
        }
    }

    .form-type-bef-checkbox {
        input:focus {
            outline: 3px solid $yale-green;
            
        }
    }

    .form-type-bef-checkbox:focus {
        border: 3px solid $yale-green;
    }

}

.second-submit,
.newsfilters {
  display: none;
}

.newsfilters.flex.hide {
  display: none!important;
}

.count-checkboxes {
  display: none;
  margin-left: 20px;
  font-family: "Mallory-Book", sans-serif;
  font-size: 16px;
  line-height: 16px;
  color: $yale-grey;
}

button.clearnewsfilter {
  display: none;
  color: $yale-med-blue;
  border: none;
  background: none;
  background-image: url('../images/clear_all_button.svg');
  background-size: 18px;
  background-position: right center;
  padding-right: 25px;
  background-repeat: no-repeat;
  font-family: "Mallory-Book", sans-serif;
  font-size: 16px;
  line-height: 16px;
  width: 125px;
  border-left: 1px solid $yale-light-grey;
  padding-left: 10px;
  margin-left: 10px;
}

.news-exposed-form {
  a.clearnewsfilter {
    display: none;
    color: $yale-med-blue;
    border: none;
    background: none;
    background-image: url('../images/clear_all_button.svg');
    background-size: 18px;
    background-position: right center;
    padding-right: 25px;
    background-repeat: no-repeat;
    font-family: "Mallory-Book", sans-serif;
    font-size: 16px;
    line-height: 16px;
    width: 125px;
    border-left: 1px solid $yale-light-grey;
    padding-left: 10px;
    margin-left: 10px;
  }
}

button.filtershowhide {
  // display: none
  color: $yale-med-blue;
  border: none;
  background: none;
  background-image: url('../images/fllter_plus_button.svg');
  background-size: 18px;
  background-position: right center;
  background-repeat: no-repeat;
  font-family: "Mallory-Book", sans-serif;
  font-size: 16px;
  line-height: 16px;
  width: 80px;
  padding-right: 25px;
}

button.filtershowhide.show {
  background-image: url('../images/fllter_minus_button.svg');
}


.second-submit {
  input.form-submit {
    background-color: $yale-blue;
    height: 60px;
    width: auto;
    background-repeat: no-repeat;
    background-position: center center;
    margin: 0 0 20px 0;
    padding: 0px 20px;
    color: #fff;
    border: none;
    transition: color 250ms cubic-bezier(.44,0,0,1);
    font-family: "Mallory-Book", sans-serif;
    font-size: 16px;
    line-height: 32px;
  }
  input.form-submit:hover {
      background-color: $yale-med-blue;
  }
}
