@font-face{
    font-family:"Mallory-Bold";
    src:url("../fonts/Mallory/Mallory-Bold.woff") format("woff"),url("../fonts/Mallory/Mallory-Bold.woff2") format("woff2");
    font-weight:normal;
    font-style:normal
}

@font-face{
    font-family:"Mallory-BoldItalic";
    src:url("../fonts/Mallory/Mallory-BoldItalic.woff") format("woff"),url("../fonts/Mallory/Mallory-BoldItalic.woff2") format("woff2");
    font-weight:normal;
    font-style:normal
}

@font-face{
    font-family:"Mallory-Book";
    src:url("../fonts/Mallory/Mallory-Book.woff") format("woff"),url("../fonts/Mallory/Mallory-Book.woff2") format("woff2");
    font-weight:normal;
    font-style:normal
}

@font-face{
    font-family:"Mallory-BookItalic";
    src:url("../fonts/Mallory/Mallory-BookItalic.woff") format("woff"),url("../fonts/Mallory/Mallory-BookItalic.woff2") format("woff2");
    font-weight:normal;
    font-style:normal
}

@font-face{
    font-family:"Mallory-Light";
    src:url("../fonts/Mallory/Mallory-Light.woff") format("woff"),url("../fonts/Mallory/Mallory-Light.woff2") format("woff2");
    font-weight:normal;
    font-style:normal
}

@font-face{
    font-family:"Mallory-LightItalic";
    src:url("../fonts/Mallory/Mallory-LightItalic.woff") format("woff"),url("../fonts/Mallory/Mallory-LightItalic.woff2") format("woff2");
    font-weight:normal;
    font-style:normal
}

@font-face{
    font-family:"Mallory-Medium";
    src:url("../fonts/Mallory/Mallory-Medium.woff") format("woff"),url("../fonts/Mallory/Mallory-Medium.woff2") format("woff2");
    font-weight:normal;
    font-style:normal
}

@font-face{
    font-family:"Mallory-MediumItalic";
    src:url("../fonts/Mallory/Mallory-MediumItalic.woff") format("woff"),url("../fonts/Mallory/Mallory-MediumItalic.woff2") format("woff2");
    font-weight:normal;
    font-style:normal
}

@font-face {
        font-family: "YaleNew-Roman";
        src: url("../fonts/YaleNew/YaleNew-normal-normal/yalenew-roman-webfont.eot");
        src: url("../fonts/YaleNew/YaleNew-normal-normal/yalenew-roman-webfont.eot?#iefix") format("embedded-opentype"),
             url("../fonts/YaleNew/YaleNew-normal-normal/yalenew-roman-webfont.woff2") format("woff2"),
             url("../fonts/YaleNew/YaleNew-normal-normal/yalenew-roman-webfont.woff") format("woff"),
             url("../fonts/YaleNew/YaleNew-normal-normal/yalenew-roman-webfont.ttf") format("truetype");
        font-weight: normal;
        font-style: normal;        
}

@font-face {
    font-family: "YaleNew";
    src: url("../fonts/YaleNew/YaleNew-normal-bold/yalenew-bold-webfont.eot");
    src: url("../fonts/YaleNew/YaleNew-normal-bold/yalenew-bold-webfont.eot?#iefix") format("embedded-opentype"),
         url("../fonts/YaleNew/YaleNew-normal-bold/yalenew-bold-webfont.woff2") format("woff2"),
         url("../fonts/YaleNew/YaleNew-normal-bold/yalenew-bold-webfont.woff") format("woff"),
         url("../fonts/YaleNew/YaleNew-normal-bold/yalenew-bold-webfont.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "YaleNew";
    src: url("../fonts/YaleNew/YaleNew-italic-normal/yalenew-italic-webfont.eot");
    src: url("../fonts/YaleNew/YaleNew-italic-normal/yalenew-italic-webfont.eot?#iefix") format("embedded-opentype"),
         url("../fonts/YaleNew/YaleNew-italic-normal/yalenew-italic-webfont.woff2") format("woff2"),
         url("../fonts/YaleNew/YaleNew-italic-normal/yalenew-italic-webfont.woff") format("woff"),
         url("../fonts/YaleNew/YaleNew-italic-normal/yalenew-italic-webfont.ttf") format("truetype");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "YaleNew";
    src: url("../fonts/YaleNew/YaleNew-italic-bold/yalenew-bolditalic-webfont.eot");
    src: url("../fonts/YaleNew/YaleNew-italic-bold/yalenew-bolditalic-webfont.eot?#iefix") format("embedded-opentype"),
         url("../fonts/YaleNew/YaleNew-italic-bold/yalenew-bolditalic-webfont.woff2") format("woff2"),
         url("../fonts/YaleNew/YaleNew-italic-bold/yalenew-bolditalic-webfont.woff") format("woff"),
         url("../fonts/YaleNew/YaleNew-italic-bold/yalenew-bolditalic-webfont.ttf") format("truetype");
    font-weight: bold;
    font-style: italic;
}