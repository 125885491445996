.node-component-social-media-slider {
    $breakout-margin: calc(-1 * (100vw - 1455px) / 2); // compensates for .maxwidth container; a liiittle fragile
    $item-size: 315px;
    $small-mq: 'only screen and (max-width: 767px)';
    $large-mq: 'only screen and (min-width: 1440px)';
    $thumb-size: 68px;

    background-color: #4A4A4A;
    color: white;
    overflow: hidden;
    padding: 0 40px;

    @media #{$small-mq} {
        padding: 0;
    }

    @media #{$large-mq} {
        // margin-left: $breakout-margin;
        // margin-right: $breakout-margin;
        padding: 0;
    }

    .component-header.flex {
        align-items: center;
        justify-content: space-between;
        margin: 40px auto;

        @media #{$small-mq} {
            display: block;
        }
    }

    .social-slider-title {
        font-size: 40px;

        @media #{$large-mq} {
            font-size: 56px;
        }
    }

    .slider-control {
        align-items: center;
        display: flex;
        height: $thumb-size;
        margin-right: -$thumb-size/2;
        position: relative;
        width: 1.5 * $item-size;

        @media #{$small-mq} {
            margin-top: 20px;
            width: 100%;
        }

        @media #{$large-mq} {
            width: 2 * $item-size;
        }
    }

    .slider-control-track {
        background: rgba(34,34,34,0.35);
        border-radius: 100px;
        height: .5rem;
        margin: 0 $thumb-size/2;
        width: 100%;

        @media #{$small-mq} {
        }
    }

    .slider-control-thumb {
        align-items: center;
        background: #222222;
        border-radius: $thumb-size/2;
        color: white;
        cursor: pointer;
        display: flex;
        height: $thumb-size;
        justify-content: center;
        position: absolute;
        transition: transform 250ms;
        width: $thumb-size;

        &.is-flipped {
            transform: rotate(180deg);
        }

        // inset border
        &::before {
            $size: 80%;
            border: 1px solid rgba(white, .2);
            border-radius: 100%;
            content: '';
            height: $size;
            position: absolute;
            width: $size;
        }
    }

    @keyframes thumb-halo {
        0% {
            opacity: 0;
        }

        50% {
            opacity: .1;
        }

        100% {
            opacity: 0;
            transform: scale(1.3);
        }
    }

    .slider-control-halo1,
    .slider-control-halo2 {
        animation: thumb-halo 3s infinite both;
        border: 3px solid white;
        border-radius: 100%;
        height: 100%;
        position: absolute;
        width: 100%;
    }

    .slider-control-halo2 {
        animation-delay: 1s;
        border-width: 1px;
    }

    .slider-control-arrow {
        $size: 40%;
        height: $size;
        transform: translateX(-2%); // optically center
        width: $size;
    }

    .view {
        // see .maxwidth
        margin: 0 auto;
        max-width: 1440px;
        padding-left: 25px;
        padding-right: 25px;
        width: 100%;
    }

    .view-content {
        box-shadow: 0 .5em 1em 0 rgba(black, .2);
        display: flex;
        flex-flow: column wrap;
        height: $item-size * 2;
        margin-bottom: 80px;
        margin-right: $breakout-margin;

        @media #{$small-mq} {
            height: $item-size;
        }
    }

    .views-row {
        align-items: stretch;
        box-sizing: border-box;
        display: flex;
        height: $item-size;
        width: $item-size;

        &.double-size {
            height: 2 * $item-size;
            width: 2 * $item-size;
        }

        @media #{$small-mq} {
            &.double-size {
                height: $item-size;
                width: $item-size;
            }
        }
    }

    .node {
        display: flex;
        flex: 1;
    }

    .socialitem {
        background-position: center;
        background-size: cover;
        color: inherit;
        display: flex;
        flex: 1;
        flex-direction: column;
        font-family: "Mallory-Book", sans-serif;
        line-height: (4/3);
        padding: 30px;
        position: relative;
        text-decoration: none;

        .body {
            flex: 1;
            position: relative;
        }

        .type {
            align-self: flex-end;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            height: 48px;
            opacity: .4;
            overflow: hidden;
            position: relative;
            text-indent: 48px;
            white-space: nowrap;
            width: 48px;
        }

        .tweet {
            background-image: url('../images/tw_icon.svg');
        }

        .facebook {
            background-image: url('../images/fb_icon.svg');
        }

        .instagram {
            background-image: url('../images/ig_icon.svg');
        }

        // &::before {
        //     background-image: linear-gradient(-225deg, rgba(74,74,74,0.45) 0%, rgba(74,74,74,0.45) 100%);
        //     content: '';
        //     height: 100%;
        //     left: 0;
        //     opacity: 0;
        //     position: absolute;
        //     top: 0;
        //     width: 100%;
        // }

        // &:hover,
        // &:focus {
        //     .type {
        //         opacity: 1;
        //     }

        //     &::before {
        //         opacity: 1;
        //     }
        // }
    }

    .instagram-item {
        &::before {
            background-image: linear-gradient(-225deg, rgba(74,74,74,0.45) 0%, rgba(74,74,74,0.45) 100%);    
            content: '';
            height: 100%;
            left: 0;
            opacity: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }

        &:hover,
        &:focus {
            .type {
                opacity: 1;
            }

            &::before {
                opacity: 1;
            }
        }
    }

    .facebook-item {
        &::before {
            background-image: linear-gradient(-225deg, rgba(0, 36, 73,0.45) 0%, rgba(0, 36, 73,0.45) 100%);
            content: '';
            height: 100%;
            left: 0;
            opacity: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }

        &:hover,
        &:focus {
            .type {
                opacity: 1;
            }

            &::before {
                opacity: 1;
            }
        }
    }

    .tweet-item {
        &::before {
            background-image: linear-gradient(-225deg, rgba(27, 74, 131, 0.45) 0%, rgba(27, 74, 131, 0.45) 100%);
            content: '';
            height: 100%;
            left: 0;
            opacity: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }

        &:hover,
        &:focus {
            .type {
                opacity: 1;
            }

            &::before {
                opacity: 0;
            }
        }
    }

    .node-tweet {
        .socialitem {
            background-image: linear-gradient(-45deg, #1B4A83 0%, #286DC0 100%);

            &:hover,
            &:focus {
                //background-image: linear-gradient(-45deg, #1B4A83 0%, #1B4A83 100%);
                background-image: none;
                background-color: #1B4A83;
            }
        }
    }

    .node-facebook {
        .socialitem {
            background-image: linear-gradient(-45deg, #002449 0%, #00356B 100%);

            &:hover,
            &:focus {
                //background-image: linear-gradient(-45deg, #002449 0%, #002449 100%);
                background-image: none;
                background-color: #002449;
            }
        }
    }

    .node-instagram {
        .socialitem {
            background-image: linear-gradient(-45deg, #002449 0%, #00356B 100%);

            &:hover,
            &:focus {
                background-image: linear-gradient(-45deg, #002449 0%, #002449 100%);
            }
        }
        div.body span.hidden {
            display: none;
        }
    }


    .social-slider-cta {
        align-items: center;
        background: #00356b url('../images/yale-dark-faded.svg') no-repeat center;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }

    .social-slider-cta-headline {
        font-family: "YaleNew-Roman", serif;
        font-size: 38px;
        margin-top: 120px;
        padding: 0 1em;

        @media #{$small-mq} {
            font-size: 24px;
            margin-top: 20px;
        }
    }

    .social-slider-cta-accounts {
        display: flex;
        list-style: none;
        margin: 10px 0;
        padding: 0;
    }

    .social-slider-cta-account {
        margin: 10px;
        overflow: hidden;
        padding: 0;

        a {
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            display: block;
            height: 48px;
            overflow: hidden;
            text-indent: 48px;
            white-space: nowrap;
            width: 48px;

            &:hover,
            &:focus {
                opacity: .4;
            }
        }
    }

    .social-slider-cta-twitter a {
        background-image: url('../images/tw_icon.svg');
    }

    .social-slider-cta-facebook a {
        background-image: url('../images/fb_icon.svg');
    }

    .social-slider-cta-instagram a {
        background-image: url('../images/ig_icon.svg');
    }

    .social-slider-cta-display {
        border-top: 1px solid rgba(white, .5);
        display: none;
        margin-top: 10px;
        padding-top: 10px;
        width: 40%;
    }
}
