
$content-width: 1390px;

.panels-flexible-region {
    max-width: 100%;
    width: 100%;
}

article {
    position: relative;
}

.flex {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    .center {
        justify-content: center;
        .center {
            align-items: center;
        }
    }
}

.clearboth {
    clear:both;
}

.tabs.primary {
    margin-top: 20px;
}

.view-ysm-people-faculty-database,
.maxwidth {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    padding-left: 25px;
    padding-right: 25px;
}

.view-ysm-people-faculty-database,
.view-ysm-people-faculty-landing {
    margin-bottom: 200px;
}

main.maxwidth {
    padding: 0px;
}

.regionmap.maxwidth {
    max-width: 1390px;
    height: 490px;
    margin-top: 20px;
    margin-bottom: 40px;
}

body.node-type-content-region {
    .regionslideshow {
        margin-bottom: 0px;
    }
}

.regionmapcontent {
    height: 490px;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    .regiontitle {
        padding: 90px 0px;
    }
}

.node-component-slideshow-with-text {
    margin-bottom: 65px;
}

.yatw-4-rows-section-second {
    div.node-component-slideshow-with-text {
        padding-top: 40px;
        padding-bottom: 10px;
        margin-top: 40px;
        margin-bottom: 0px;
    }
}

.node-component-slideshow-with-text .flex {
    -webkit-justify-content: space-between;
    justify-content: space-between;
    // max-height: 490px;
    .right {
        max-width: 720px;
        width: 100%;
    }
}

.node-component-slideshow-with-text .left {
    margin-left: 120px;
    max-width: 480px;
    width: 100%;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    .field-name-field-display-title {
        padding-top: 20px;
    }
    div.linkbutton a {
        margin-top: 20px;
    }

}

.yalebanner {
    margin-bottom: 17px;
}

.yalebanner.flex {
    height: 56px;
}

header#header .flex {
    justify-content: space-between;
}

body.page-navigation404 {
    footer#footer {
        margin-top: 50px;
    }
    main {
        max-width: 1390px;
        width: 100%;
        margin: auto;
    }
    ul.action-links {
        margin-left: 20px;
        margin-bottom: 20px;
    }
}

main {
    .greycontentborder {
        position: relative;
        margin-left: -46px;
        max-width: 1240px;
        padding-top: 50px;
        padding-bottom: 50px;
        padding-left: 160px;

        img {
          width: 100%;
          height: 100%;
        }
    }
    .node-content-news {
      .greycontentborder {
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 0px;
      }
    }
    .panel-pane.pane-node-body {
        margin-bottom: 0px;
    }
    .greycontentbordercontent {
      padding-top: 50px;
      padding-left: 160px;
      padding-right: 160px;
      .newsbyline {
        margin-bottom: 40px;
      }
    }
}

article.node-type-content-news {
  .newsbody {
    .verticalimage {
      float: right;
      max-width: 425px;
      height: auto;
      margin-left: 40px;
      margin-bottom: 40px;
    }
  }
}

.view-all-news {
    margin-top: 15px;
    .greycontentborder {
        margin-left: -20px;
    }
}

body.node-type-component-tableau-map-embed {
    main {
        min-height: 600px;
    }
}

div.google-map-iframe-embed {
    .content {
        height: 600px;
        max-width: 960px;
        width: 100%;
        margin: auto;
        margin-bottom: 100px;
        iframe {
            height: 600px;
        }
    }
}

body.page-search-site {
    #block-system-main {
        > .content {
            margin-left: -46px;
            max-width: 1240px;
            padding-top: 50px;
            padding-bottom: 50px;
            padding-left: 160px;
        }
    }
}

body.node-type-content-news main,
body.node-type-content-landing-page main {
    margin-top: 15px;
}

body.node-type-content-landing-page main .contenttitle,
.view-all-news .contenttitle,
article.node-content-news header {
    height: 250px;
    padding-left: 120px;
    align-items: center;
    max-width: 1000px;
    width: 100%;
    .headertop {
        height: 200px;
        align-items: center;
    }
}

article.node-content-news header {
    min-height: 250px;
    height: auto;
    .headertop {
        min-height: 200px;
        height: auto;
    }
}

article.node-content-news {
  figure.newsimage {
    figcaption {
      width: 100%;
      justify-content: space-between;
      padding-left:160px;
      padding-right: 160px;
      padding-top: 10px;
    }
  }
  figure.verticalimage {
    float:right;
    margin: 0px;
    margin-bottom: 40px;
    margin-left: 15px;
    width: 50%;
    figcaption {
      justify-content: space-between;
    }
  }
}


.node-content-landing-page .field-name-body,
.node-type-content-landing-page main .field-name-body,
.node-type-content-landing-page main .pane-custom,
article.node-content-news .greycontentborder > div.field-name-body {
    max-width: 746px;
}

// div.node-component-two-wide-cta-boxes > .content.flex > div.a {
//     display: -webkit-box;
//     display: -moz-box;
//     display: -ms-flexbox;
//     display: -webkit-flex;
//     display: flex;
//     align-items: flex-start;
//     span {
//         height: auto;
//     }
// }

div.node-component-two-wide-cta-boxes.blueboxes > .flex {
    -webkit-justify-content: space-between;
    justify-content: space-between;
    > div {
        max-width: 505px;
        width: 100%;
    }
    > div:last-child {
        margin-right: 0px;
    }
}

div.node-component-two-wide-cta-boxes.greyboxes.threecol > .flex,
div.node-component-two-wide-cta-boxes.blueboxes.threecol > .flex {
    -webkit-justify-content: space-between;
    justify-content: space-between;
    > div {
        max-width: 450px;
        width: 100%;
    }
    > div:nth-child(2) {
        margin-left: 15px;
        margin-right: 15px;
    }
    > div:last-child {
        margin-right: 0px;
    }
 }

.node-type-alternate-homepage {

  .node-component-horizontal-link-bar {
    margin: 20px auto;
  }

  .node-component-two-wide-cta-boxes {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  div.node-component-two-wide-cta-boxes.greyboxes.threecol > .flex,
  div.node-component-two-wide-cta-boxes.blueboxes.threecol > .flex {
    max-width: 1390px;
    width: 100%;
    margin: auto;
  } 
}


div.node-component-two-wide-cta-boxes.greyboxes > .flex {
    -webkit-justify-content: space-between;
    justify-content: space-between;
    > div {
        max-width: 690px;
        width: 100%;
    }
    > div:last-child {
        margin-right: 0px;
    }
}

section.yatw-4-rows-section {
    div.node-component-two-wide-cta-boxes.greyboxes > .flex {
        > div {
            max-width: 505px;
        }
    }
}

body.node-type-content-homepage {

    section.yatw-4-rows-section {
        div.node-component-two-wide-cta-boxes.greyboxes > .flex {
            > div {
                max-width: 690px;
            }
            > div:first-child {
                margin-right: 10px;
            }
        }
    }

    section.yatw-4-rows-section-second {
        margin-top: 30px;
    }

    section.yatw-4-rows-section-second,
    section.yatw-4-rows-section-forth {
        .node-component-two-wide-cta-boxes.blueboxes {
            .content.flex {
                max-width: 1390px;
                margin-left: 0px;
                -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
                > div {
                    margin-bottom: 0px;
                }
            }
        }

        .node-component-two-wide-cta-boxes.greyboxes.onecol,
        .node-component-two-wide-cta-boxes.blueboxes.onecol {
            .content {
                max-width: 100%;
                .first {
                    width: 100%;
                    margin: 0px;
                }
            }
        }

        div.node-component-two-wide-cta-boxes.blueboxes.onecol {
            > .flex {
                > div {
                    width: 100%;
                    max-width: 100%;
                }
            }
        }

    }

    div.node-component-two-wide-cta-boxes.greyboxes,
    div.node-component-two-wide-cta-boxes.blueboxes {
        > .content.flex > div {
            padding: 50px 120px;
        }
    }

    div.node-component-two-wide-cta-boxes.greyboxes.threecol,
    div.node-component-two-wide-cta-boxes.blueboxes.threecol {
        > .content.flex > div {
            padding: 50px 75px;
        }
    }

}

body.node-type-content-region {
    section.yatw-4-rows-section-first,
    section.yatw-4-rows-section-third {

        .node-component-two-wide-cta-boxes.greyboxes,
        .node-component-two-wide-cta-boxes.blueboxes {
            .content.flex {
                max-width: 1020px;
                -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
                > div {
                    margin-bottom: 0px;
                }
            }
        }

        .node-component-two-wide-cta-boxes.greyboxes.threecol,
        .node-component-two-wide-cta-boxes.blueboxes.threecol {
            .content.flex {
                max-width: 930px;
                -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
                > div {
                    margin-bottom: 15px;
                }
            }
        }

        .node-component-two-wide-cta-boxes.greyboxes.onecol,
        .node-component-two-wide-cta-boxes.blueboxes.onecol {
            .content {
                max-width: 100%;
                .first {
                    width: 100%;
                    margin: 0px;
                }
            }
        }

    }

    section.yatw-4-rows-section-second,
    section.yatw-4-rows-section-forth {

        .node-component-two-wide-cta-boxes.greyboxes,
        .node-component-two-wide-cta-boxes.blueboxes {
            .content.flex {
                max-width: 1440px;
                margin-left: 0px;
                -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
                -webkit-justify-content: flex-start;
                justify-content: flex-start;
                > div {
                    margin-bottom: 0px;
                }
                > div:first-child {
                    margin-right: 20px;
                }
            }
        }

        .node-component-two-wide-cta-boxes.greyboxes.threecol,
        .node-component-two-wide-cta-boxes.blueboxes.threecol {
            .content.flex {
                max-width: 1440px;
                margin-left: 0px;
                -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
                -webkit-justify-content: flex-start;
                justify-content: flex-start;
                > div {
                    margin-bottom: 15px;
                }
                > div:first-child {
                    margin-right: 0px;
                }
            }
        }

        .node-component-two-wide-cta-boxes.greyboxes.onecol,
        .node-component-two-wide-cta-boxes.blueboxes.onecol {
            .content {
                max-width: 100%;
                .first {
                    max-width: 100%;
                    width: 100%;
                    margin-right: 0px;
                }
                > div:first-child {
                    margin-right: 0px;
                }
            }
        }

    }

}

body.node-type-content-landing-page {
    section.yatw-4-rows-section-first,
    section.yatw-4-rows-section-third {

        .node-component-two-wide-cta-boxes.greyboxes,
        .node-component-two-wide-cta-boxes.blueboxes {
            .content.flex {
                max-width: 1020px;
                -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
                > div {
                    margin-bottom: 0px;
                }
            }
        }
        .node-component-two-wide-cta-boxes.blueboxes {
            .content.flex {
                max-width: 1390px;
            }
        }

        .node-component-two-wide-cta-boxes.greyboxes.threecol,
        .node-component-two-wide-cta-boxes.blueboxes.threecol {
            .content.flex {
                max-width: 930px;
                -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
                > div {
                    margin-bottom: 15px;
                }
            }
        }

        .node-component-two-wide-cta-boxes.greyboxes.onecol,
        .node-component-two-wide-cta-boxes.blueboxes.onecol {
            margin-right: 20px;
            .content {
                max-width: 1020px;
                width: 100%;
                .first {
                    max-width: 100%;
                    width: 100%;
                    margin-right: 0px;
                }
                > div:first-child {
                    margin-right: 0px;
                }
            }
        }

    }



    section.yatw-4-rows-section-second,
    section.yatw-4-rows-section-forth {

        .node-component-two-wide-cta-boxes.greyboxes,
        .node-component-two-wide-cta-boxes.blueboxes {
            .content.flex {
                padding-left: 120px;
                max-width: 1140px;
                margin-left: 0px;
                -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
                > div {
                    margin-bottom: 0px;
                }
            }
        }

        .node-component-two-wide-cta-boxes.greyboxes.threecol,
        .node-component-two-wide-cta-boxes.blueboxes.threecol {
            .content.flex {
                max-width: 1160px;
                margin-left: 0px;
                -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
                > div {
                    margin-bottom: 15px;
                }
            }
        }

        .node-component-two-wide-cta-boxes.greyboxes.onecol,
        .node-component-two-wide-cta-boxes.blueboxes.onecol {
            .content {
                max-width: 100%;
                .first {
                    max-width: 100%;
                    width: 100%;
                    margin-right: 0px;
                }
                > div:first-child {
                    margin-right: 0px;
                }
            }
        }

    }
}


div.node-component-news-list-by-term > div.content > h3 {
    margin-bottom: 45px;
}

div.view-related-news-by-term-component .views-row {
    clear: both;

    // .views-field-title {
    // }
    .views-field-field-image {
        float: left;
        margin-right: 50px;
    }

}

.node-component-news-list-by-term {
    .view-related-news-by-term {
        .view-content {
            max-width: 1080px;
            margin-left: 120px;
        }
        // .views-row {}
        // .views-field-title {}
        // .views-field-body {}
    }
}

div.node-component-three-wide-cta-boxes .flex {
    max-width: 1150px;
    width: 100%;
    margin: auto;
}

section.yatw-4-rows-section {
    > div {
        margin-bottom: 40px;
    }
}



section.yatw-4-rows-section-forth {
    // padding-left: 114px;
    max-width: 1390px;
}

.view-resource-list {
    .view-content {
        max-width: 790px;
        width: 100%;
    }
}

.pageheader .pageheadersearch {
    margin-top: 20px;
}

.pageheader form.search-form .form-type-textfield.form-item-search-block-form {
    margin-right: 10px;
}

.worldmapitems {
    margin-top: 20px;
}

.worldmapnames {
    max-width: 1150px;
    width: 100%;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    > div {
        max-width: 380px;
        width: 100%;
        // a {

        // }
    }
}

.node-content-profile,
.node-component-profile-list {
    .profilenode {
        .flex.emailtitle {
            -webkit-justify-content: space-between;
            justify-content: space-between;
        }
    }
    .staffprofile {
        max-width: 900px;
        width: 100%;
    }
    .fellowprofile {
        max-width: 900px;
        width: 100%;
    }
}

.node-content-profile {
    max-width: 1390px;
    width: 100%;
    margin: auto;
    .flex {
        .left {
            width: 190px;
            margin-right: 20px;
        }
        .right {
            h2 {
                color: $yale-blue;
            }
        }
    }
}

div.breadcrumb {
    margin-top: 40px;
    margin-bottom: 20px;
}

div.sitealert {
    width: 100%;
}

div.site-alert div.text {
    margin: 0px;
    padding: 0px;
}


div.site-alert div.info,
div.site-alert div.low,
div.site-alert div.high,
div.sitealert > div {
    margin-bottom: 0px;
    height: 85px;
    width: 100%;
        .flex {
            max-width: 1440px;
            margin: auto;
            padding: 10px 20px;
            .left {
                margin-right: 10px;
            }
            .right {
                padding-right: 100px;
            }
        }
}

div.node-component-video-player-banner .content {
    padding-bottom: 20px;
    padding-left: 120px;
}

.pane-content .node-component-three-wide-arrow-cta {
    margin: 40px auto;
    max-width: 1390px;
}


#vizContainer {
    margin: 20px 0px;
    iframe {
        margin: auto;
    }
}

body.page-search-site {
    form.search-form {
        margin-bottom: 40px;
    }
    form.search-form,
    h2,
    .ds-search-extra,
    .spelling-suggestions {
        max-width: 1390px;
        width: 100%;
        margin: auto;
    }
    .ds-search-extra {
        margin-bottom: 40px;
    }
    article {
        max-width: 1390px;
        width: 100%;
        margin: auto;
        .content,
        .searchresult {
            max-width: 790px;
            width: 100%;
            margin-bottom: 40px;
        }
    }
    .pageheadersearch {
        div.form-actions {
            margin-left: 10px;
        }
    }
}


.search-form {
    div.form-wrapper {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        padding: 40px;
        padding-top: 40px;
        background-color: $yale-white;
        max-width: 900px;
        width: 100%;
        .form-item.form-type-textfield {
            margin-top: 0px;
            margin-bottom: 0px;
            width: 100%;
            label {
                // display: none;
            }
        }
    }
}

.pageheader {
    input.custom-search-box {
        margin-right: 10px;
    }
}

.pageheadersearch {
    div.form-wrapper {
        max-width: auto;
        width: auto;
        margin: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
        padding: 0px;
        padding-top: 0px;
        background-color: none;
        display: block;

        .form-item.form-type-textfield {
            margin-top: 0px;
            margin-bottom: 0px;
            margin-right: 10px;
            label {
                // display: none;
            }
        }
    }
}

.pageheadersearch {
    div.form-actions {
        margin-left: 10px;
    }
}

.node-component-inline-callout-box {
    .content.flex {
        -webkit-flex-direction: column;
        flex-direction: column;
        max-width: 910px;
        width: 100%;
        padding: 30px 50px;
        > .flex {
            -webkit-flex-direction: row;
            flex-direction: row;
            -webkit-justify-content: space-between;
            justify-content: space-between;

            > div {
                max-width: 40%;
                width: 100%;
                margin-top: 20px;
            }
        }
    }
}


body.node-type-webform {
    footer#footer {
        margin-top: 0px;
    }
}


footer#footer {
    margin-top: -156px;
    height: 305px;
    .flex {
        align-items: flex-end;
    }
}

body.front footer#footer {
    margin-top: -256px;
}

body.node-type-content-resource,
body.node-type-content-profile,
body.page-user {
    footer#footer {
        margin-top: 100px;
    }
}

footer#footer > .maxwidth > .flex {
    padding-bottom: 60px;
    height: 305px;
}

footer#footer > .maxwidth > .flex > .left > .left {
    margin-right: 20px;
}

.pageheadersearch {
    .left {
        padding-top: 22px;
    }
    div.form-actions {
        margin-top: 22px;
    }
}

.node-component-cta-with-image {
  .content {
    max-width: $content-width;
    width: 100%;
    margin: auto;
    height: 100%;
    .cta-content {
        ul {
            margin: 1rem;
            li {
                font-family: Mallory-Book,sans-serif;
                font-size: 18px;
                line-height: 30px;
            }
        }
    }
    .first {
      max-width: 790px;
      width: 100%;
      margin-right: 20px;
      padding: 50px 0px;
    }
    .first.margin-left {
      margin-left: 70px;
      margin-right: 120px;
    }
    .first.margin-right {
      margin-right: 70px;
      margin-left: 120px;
    }
    .second {
      max-width: 600px;
      width: 100%;
      div {
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.newssearch {
  padding-top: 10px;
  margin-bottom: 20px;
  .newssearchkeyword {
    width: 80%;
  }
  .views-exposed-widget.views-submit-button {
    margin: 0px;
    margin-left: 10px;
    padding: 0px;
  }
}

.newsfilters {
  .regioncheckboxes {
    .form-item-region {
      .bef-checkboxes {
        // -webkit-columns: 2 200px;
        // -moz-columns: 2 200px;
        columns: 2 200px;
        // -webkit-column-gap: 2em;
        // -moz-column-gap: 2em;
        column-gap: 2em;
        input {
          font-size: 20px;
        }
      }
    }
  }
}


body.node-type-alternate-homepage {
  div.node-component-news-list-by-term {
    max-width: 1390px;
    width: 100%;
    margin: auto;
    margin-bottom: 20px;
  }
  footer {
    clear:both;
  }
}


.node-component-horizontal-link-bar {
  max-width: 1390px;
  width: 100%;
  margin: auto;
  background-color: $yale-green;
  padding: 40px 80px;
  .content {
    >.flex {
      justify-content: space-between;
      .left {
        color: #fff;
        p {
          font-family: Mallory-Book,sans-serif;
          font-size: 18px;
          line-height: 30px;
        }
      }
      .right {
        
        >.flex {
          flex-direction: column;
          .ctabutton {
            margin-bottom: 20px;
          }
          .ctabutton:last-child {
            margin-bottom: 0px;
          }
          a {
            color: #fff;
          }
          a:hover {
            color: $yale-blue;
          }
        }
      }
    }
  }
}