@mixin psuedo-rendering { 
    content: "";
    display: block;
    width: 350px;
    height: 350px;
    position: absolute;
    background: #fff;
    z-index:-10
}

$yale-blue : #00356B;
$yale-med-blue : #286DC0;
$yale-light-blue : #63AAFF;
$yale-blue-hover : #51cbee;
$yale-grey : #4A4A4A;
$yale-white : #fff;
$yale-black : #222222;
$yale-med-grey : #6e6e6e;
$yale-light-grey : #ddd;
$yale-white : #f9f9f9;
$yale-brown : #988D85;
$yale-beige : #DCE0D1;
$yale-accessable-brown : #757575;
$yale-menu : #DDDDDD;
$yale-green : #5F712D;
$yale-orange : #bd5319;
$yale-red : #ee4408;
$black : #000;

h1 {
    font-family: "YaleNew-Roman", serif;
    font-size: 60px;
    font-weight: normal;
    margin: 0px;
    padding: 0px;
}

h2 {
    font-family: "YaleNew-Roman", serif;
    font-size: 56px;
    line-height: 68px;
    font-weight: normal;
    margin: 0px;
    padding: 0px;
}

h3 {
    font-family: "YaleNew-Roman", serif;
    font-size: 38px;
    line-height: 42px;
}

h4 {
    font-family: "YaleNew-Roman", serif;
    font-size: 28px;
    line-height: 34px;
}

h5 {
    font-family: "YaleNew-Roman", serif;
    font-size: 24px;
    line-height: 30px;
}

@media only screen and (max-width: 768px) {

    // changes to H tags for mobile sizing.

    h1 { font-size: 40px; }
    h2,
    .headertop h2 { font-size: 34px; line-height: 44px; margin-bottom: 5px; }
    h3 { font-size: 30px; line-height: 40px; margin-bottom: 5px; }
    h4 { font-size: 26px; line-height: 30px; margin-bottom: 5px; }
    h5 { font-size: 22px; line-height: 26px; margin-bottom: 5px; }

}

.pane-custom,
.field-name-body {
    
    h2 { color: $yale-grey; }
    h3 { color: $yale-grey; }
    h4 { color: $yale-grey; }
    h5 { color: $yale-grey; }

    strong {
        font-family:"Mallory-Bold";
        font-weight: bold;
    }

    em {
        font-family:"Mallory-BookItalic";
        font-style: normal;
    }

    strong em,
    em strong {
        font-family:"Mallory-BoldItalic";
        font-style: normal;
    }

}


a {
    color: $yale-blue;
}

p {
    margin: 1em 0;
}

.pane-custom P,
.field-name-body p,
.field-name-body li {
    font-family: "Mallory", sans-serif;
    font-size: 18px;
    line-height: 30px;
    color: $yale-grey;
}

.pane-custom ul,
.field-name-body ul {
    margin-left: 20px;
}

div.node-component-two-wide-cta-boxes h3 {
    color: $yale-white;
}

div.node-component-three-wide-arrow-cta h3 {
    color: $yale-blue;
}

div.node-component-two-wide-cta-boxes p {
    font-size: 18px;
    line-height: 30px;
}


a:focus,
button:focus {
    border: 4px solid $yale-green;
}

ul.menu li.menuparent ul li a:focus {
    border: 4px solid $yale-green
}

.linkbutton.blue a:focus {
    border: 4px solid $yale-green
}

.linkbutton.blue a:focus {
    border: 4px solid $yale-green
}

div.node-component-video-player-banner .videoselectors #videolistopenwords button:focus {
    border: 4px solid $yale-green
}

.yalebanner a:focus,
.greyboxes .linkbutton a:focus {
    border: 4px solid $yale-green
}