.mobileheader {
    display: none;
    visibility: hidden;
}

.mobilenav,
.mobilenavitem {
    display: none;
}

.mobilenav.open {
    display: block;
}

@media only screen and (max-width: 1439px) {

  .node-component-cta-with-image {
    .content {
      .first {

        width: 50%;
      }
      .second {
        min-width: 600px;
        width: 50%;
      }
    }
  }

}

@media only screen and (max-width: 1400px) {
  

  .node-component-two-wide-cta-boxes.greyboxes,
  .node-component-two-wide-cta-boxes.blueboxes {
    >.flex {
      flex-wrap: wrap;
    }
  }

  div.node-component-two-wide-cta-boxes.blueboxes {
    >.content.flex {
      >div {
        max-width: 50%;
      }
      >div:nth-child(2) {
        margin: 0px;
      }
    }
  }
  
  div.node-component-two-wide-cta-boxes.blueboxes.threecol, 
  div.node-component-two-wide-cta-boxes.greyboxes.threecol {
    >.flex {
      >div:nth-child(2) {
        margin: 0px;
      }
      >div:last-child {
        max-width: 100%;
        width: 100%;
      }
    }
  }


}

@media only screen and (max-width: 1280px) {
    #hometitle,
    .worldmapnames,
    .node-component-slideshow-with-text .left {
        margin-left: 20px;
        margin-right: 20px;
    }

    div.node-component-news-list-by-term .content {
        width: auto;
    }

    div.node-component-two-wide-cta-boxes.greyboxes > .content.flex > div {
        padding: 20px;
    }

    div.node-component-two-wide-cta-boxes.greyboxes > .content.flex > div:first-child {
        margin-right: 10px;
    }

    .pane-content .node-component-three-wide-arrow-cta {
        .content.flex {
            -webkit-justify-content: space-between;
            justify-content: space-between;
            div:first-child,
            div:last-child,
            div {
                margin: 0px;
            }
            div:first-child {
                margin-right: 10px;
            }
            div:last-child {
                margin-left: 10px;
            }
        }
    }

    div.node-component-news-list-by-term .content {
        .flex {
            h3 {
                margin-left: 20px;
            }
        }
        .view {
            .view-content {
                margin-left: 20px;
            }
        }
    }

    body.node-type-content-landing-page {
        section.yatw-4-rows-section-second {
            .node-component-two-wide-cta-boxes.greyboxes.threecol {
                .content.flex{
                    max-width: 1140px;
                    > div {
                        margin: 0px;
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }

    body.node-type-content-landing-page {
        section.yatw-4-rows-section-second {
            .node-component-two-wide-cta-boxes.blueboxes.threecol {
                .content.flex{
                    max-width: 1140px;
                    > div {
                        margin: 0px;
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }

    body.node-type-content-landing-page {
        section.yatw-4-rows-section-forth {
            .node-component-two-wide-cta-boxes.greyboxes.threecol {
                .content.flex{
                    max-width: 1140px;
                    > div {
                        margin: 0px;
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }

    body.node-type-content-landing-page {
        section.yatw-4-rows-section-forth {
            .node-component-two-wide-cta-boxes.blueboxes.threecol {
                .content.flex{
                    max-width: 1140px;
                    > div {
                        margin: 0px;
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }

    div.node-component-news-list-by-term {
        > .content {
            > .flex {
                max-width: 1080px;
            }
        }
    }

}

@media only screen and (max-width: 1200px) {

    body.node-type-content-homepage.showtriangle, body.node-type-content-region.showtriangle {
        background: none;
    }

    .worldmapitems {
        height: 750px;
    }
}

@media only screen and (max-width: 1189px) {

    // body.node-type-content-landing-page {
    //     section.yatw-4-rows-section-first,
    //     section.yatw-4-rows-section-third {
    //         .node-component-two-wide-cta-boxes.blueboxes {
    //             .content.flex {
    //                 -webkit-flex-direction: column;
    //                 flex-direction: column;
    //                 > div {
    //                     max-width: 100%;
    //                     width: 100%;
    //                     margin: 0px;
    //                     margin-bottom: 10px;
    //                 }
    //             }
    //         }
    //     }
    // }

    body.node-type-content-landing-page {
        section.yatw-4-rows-section-second {
            .node-component-two-wide-cta-boxes.greyboxes {
                .content.flex {
                    > div {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
    body.node-type-content-landing-page {
        section.yatw-4-rows-section-second {
            .node-component-two-wide-cta-boxes.blueboxes {
                .content.flex {
                    > div {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
    body.node-type-content-landing-page {
        section.yatw-4-rows-section-forth {
            .node-component-two-wide-cta-boxes.greyboxes {
                .content.flex {
                    > div {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
    body.node-type-content-landing-page {
        section.yatw-4-rows-section-forth {
            .node-component-two-wide-cta-boxes.blueboxes {
                .content.flex > div {
                    margin-bottom: 10px;
                }
            }

        }
    }
    div.node-component-two-wide-cta-boxes.greyboxes {
        > .content.flex {
            > div:first-child {
                margin: 0px;
                margin-bottom: 10px;
            }
        }
    }

    section.yatw-4-rows-section {
        div.node-component-two-wide-cta-boxes.greyboxes {
            > .flex {
                > div {
                    max-width: 100%;
                    width: 100%;
                    margin: 0px;
                    margin-bottom: 10px;
                }
            }
        }
    }

}

@media only screen and (max-width: 1180px) {
    body.node-type-content-landing-page {
        section.yatw-4-rows-section-second {
            .node-component-two-wide-cta-boxes.greyboxes {
                .content.flex {
                    > div {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }

    body.node-type-content-landing-page {
        section.yatw-4-rows-section-second {
            .node-component-two-wide-cta-boxes.blueboxes {
                .content.flex {
                    -webkit-flex-direction: column;
                    flex-direction: column;
                    > div {
                        margin-bottom: 10px;
                        width: 100%;
                        max-width: 100%;
                    }
                }
            }
        }
    }

    body.node-type-content-landing-page {
        section.yatw-4-rows-section-forth {
            .node-component-two-wide-cta-boxes.greyboxes {
                .content.flex {
                    > div {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }

    body.node-type-content-landing-page {
        section.yatw-4-rows-section-forth {
            .node-component-two-wide-cta-boxes.blueboxes {
                .content.flex {
                    -webkit-flex-direction: column;
                    flex-direction: column;
                    > div {margin-bottom: 10px;}
                }
            }
        }
    }

    div.node-component-two-wide-cta-boxes.greyboxes {
        > .content.flex {
            > div:first-child {
                margin: 0px;
                margin-bottom: 10px;
            }
        }
    }

    section.yatw-4-rows-section {
        div.node-component-two-wide-cta-boxes.greyboxes {
            > .flex {
                > div {
                    max-width: 100%;
                    width: 100%;
                    margin: 0px;
                    margin-bottom: 10px;
                }
            }
        }
    }

    .node-component-cta-with-image {
      .content {
        .first {

          width: 50%;
        }
        .second {
          min-width: 0;
          width: 50%;
        }
      }
    }

}

@media only screen and (max-width: 1140px) {



}

@media only screen and (max-width: 1100px) {
    section.yatw-4-rows-section-second {
        .node-component-two-wide-cta-boxes.blueboxes {
            .content.flex {
                padding-left: 0px;

                > div {
                    max-width: 100%;
                }
                // > div:first-child {

                // }
                > div:last-child {
                    margin-top: 10px;
                }
            }
        }
        .node-component-two-wide-cta-boxes.greyboxes {
            .content.flex {
                padding-left: 0px;
                -webkit-flex-direction: column;
                flex-direction: column;
                > div {
                    max-width: 100%;
                    width: 100%;
                }
            }
        }
    }

    body.node-type-content-homepage {
        section.yatw-4-rows-section-second {
            .node-component-two-wide-cta-boxes.greyboxes {
                .content.flex {
                    -webkit-flex-direction: column;
                    flex-direction: column;
                    > div {
                        max-width: 100%;
                        width: 100%;
                    }
                    > div:first-child {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }

    body.node-type-content-landing-page main .contenttitle,
    article.node-content-news header {
        padding-left: 0px;
    }
    main .greycontentborder {
        padding-left: 40px;
    }

    body.node-type-content-homepage {
        section.yatw-4-rows-section-second {
            .node-component-two-wide-cta-boxes.blueboxes {
                .content.flex {
                    max-width: 100%;
                }
            }
        }
    }

    body.node-type-content-homepage {
        section.yatw-4-rows-section-forth {
            .node-component-two-wide-cta-boxes.blueboxes {
                .content.flex {
                    max-width: 100%;
                }
            }
        }
    }

    body.node-type-content-landing-page {
        section.yatw-4-rows-section-second {
            .node-component-two-wide-cta-boxes.greyboxes {
                .content.flex {
                    padding-left: 0px;
                    > div {
                        width: 100%;
                        max-width: 100%;
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }

    body.node-type-content-landing-page {
        section.yatw-4-rows-section-second {
            .node-component-two-wide-cta-boxes.blueboxes {
                .content.flex {
                    padding-left: 0px;
                    > div {
                        width: 100%;
                        max-width: 100%;
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }

    body.node-type-content-landing-page {
        section.yatw-4-rows-section-forth {
            .node-component-two-wide-cta-boxes.greyboxes {
                .content.flex {
                    padding-left: 0px;
                    > div {
                        width: 100%;
                        max-width: 100%;
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }

    body.node-type-content-landing-page {
        section.yatw-4-rows-section-forth {
            .node-component-two-wide-cta-boxes.blueboxes {
                .content.flex {
                    padding-left: 0px;
                    > div {
                        width: 100%;
                        max-width: 100%;
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }

    div.mainmenu > nav a {
        font-size: 20px;
    }

}

@media only screen and (max-width: 1099px) {
    .node-component-slideshow-with-text .flex {
        -webkit-flex-direction: column;
        flex-direction: column;
        > div.left {
            order: 2;
            width: 100%;
            max-width: 1100px;
        }
        > div.right {
            order: 1;
            width: 100%;
        }
    }
}

@media only screen and (max-width: 1080px) {

    div.node-component-news-list-by-term {
        background: none;
        > .content {
            > .flex {
                max-width: 1080px;
                padding-right: 20px;
            }
        }
    }

}

@media only screen and (max-width: 1070px) {
    .regionsbutton {
        display: none;
    }
}

@media only screen and (max-width: 1039px) {

  .node-content-news {
    .greycontentbordercontent {
        figure.verticalimage {
          margin: 0px;
          margin-bottom: 40px;
          margin-left: 15px;
          width: 40%;
        }
    }
  }


}

@media only screen and (max-width: 1024px) {

  .node-component-two-wide-cta-boxes.greyboxes,
  .node-component-two-wide-cta-boxes.blueboxes {
    >.flex {
      flex-direction: column;
    }
  }

  div.node-component-two-wide-cta-boxes.blueboxes.threecol>.flex>div:nth-child(2), 
  div.node-component-two-wide-cta-boxes.greyboxes.threecol>.flex>div:nth-child(2) {
    margin: 0px;
  }

  div.node-component-two-wide-cta-boxes.greyboxes.threecol,
  div.node-component-two-wide-cta-boxes.blueboxes.threecol {
    >.flex {
      >div {
        max-width: 100%;
        width: 100%;
      }
    }
  }

    html,body {
        overflow-x: hidden;
    }

    .pane-content .node-component-three-wide-arrow-cta .content.flex {
        -webkit-flex-direction: column;
        flex-direction: column;
        div:first-child,
        > div {
            margin-bottom: 10px;
            padding: 20px;
            padding: 0px;
            background-image: none;
        }
        > div:last-child {
            margin: 0px;
        }
    }

    div.node-component-three-wide-arrow-cta .flex > div .arrowlink a.flex {
        padding-right: 60px;
    }

    body.node-type-content-landing-page {
        section.yatw-4-rows-section-first,
        section.yatw-4-rows-section-third {
            .node-component-two-wide-cta-boxes.blueboxes {
                .content.flex {
                    -webkit-flex-direction: column;
                    flex-direction: column;
                    > div {
                        max-width: 100%;
                        width: 100%;
                        margin: 0px;
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }

    ul#superfish-1 {
        height: 36px;
    }

    div.mainmenu > nav a {
        font-size: 20px;
    }

    div.node-component-news-list-by-term {
        background: none;
        > .content {
            max-width: 1024px;
            width: 100%;
        }
        .view-related-news-by-term {
            max-width: 1024px;
            .node-content-news,
            .view-content {
                max-width: 1024px;
                width: 100%;
            }
        }
    }

    div.node-component-video-player-banner {
        .content {
            padding-left: 30px;
        }
    }

    body.node-type-content-homepage div.node-component-two-wide-cta-boxes.greyboxes > .content.flex > div,
    body.node-type-content-homepage div.node-component-two-wide-cta-boxes.blueboxes > .content.flex > div {
        padding: 50px 30px;
    }

    article.node-content-news {
        div.breadcrumb {
            margin-top: 0px;
        }
        header .headertop {
            align-content: flex-start;
            align-items: flex-start;
        }
    }

    .worldmapitems .worldmapitem img, .worldmapitems .defaultworldmapitem img {
        width: 100%;
    }

    .worldmapnames > div {
        max-width: 49%;
    }

    .node-component-cta-with-image {
      .content {
        .cta-content {
          order: 2;
        }
        .second {
          order: 1;
        }

        .first.margin-right,
        .margin-right {
          margin-left: 60px;
        }
        .first.margin-left,
        .margin-left {
          margin-right: 60px;
        }

      }
    }

}

@media only screen and (max-width: 1023px) {


  div.node-component-news-list-by-term .content {
    .flex {
      h3 {
        margin-left: 0px;
      }
    }
    .view {
      .view-content {
        margin-left: 0px;
        .node {
          .right {
            padding: 0 10px
          }
        }
      }
    }
  }

  .node-component-horizontal-link-bar {
    .content {
      .flex {
        .left {
          margin-right: 20px;
        }
      }
    }
  }

    div.node-component-news-list-by-term {
        margin: 0px 10px;
        background: none;
        > .content {
            width: 100%;
        }
        .view-related-news-by-term {
            width: 100%;
            .view-content {
                padding: 0px;
            }
            .views-row {
                .node-content-news {
                    .flex {
                        -webkit-flex-direction: column;
                        flex-direction: column;
                        .left {
                            max-width: 100%;
                            width: 100%;
                            margin: 0px;
                            img {
                                max-width: 100%;
                                width: 100%;
                            }
                        }
                        .right {
                        }
                    }
                }
            }
        }
    }

    .node-content-news {
        .flex {
            .left {
                max-width: 100%;
                width: 100%;
                margin: 0px;
                img {
                    max-width: 100%;
                    width: 100%;
                }
            }
        }
    }

    .node-content-news {
      .greycontentbordercontent {
        padding-left: 30px;
        padding-right: 20px;
        .newsbody {}
      }
    }

    .view-all-news {
        div.node-content-news {
            .left {

                width: 100%;
                img {
                    width: 100%;
                }
            }
            .right {
              width: 75%;
            }
        }
    }

    article.node-content-news {
       figure.newsimage {
         figcaption {
           padding-left: 30px;
           .first {
               margin-right: 20px;
           }
         }
       }
    }

    #hometitle {

        .regionsbutton {
            position: absolute;
            top: 220px;
            right: 80px;
            z-index: 1000;
        }

    }

    .node-component-cta-with-image {
      .content {
        flex-direction: column;
        .first.margin-left,
        .first.margin-right {
          margin-left: 0;
          margin-right: 0;
          padding: 40px 40px;
        }
        .first {
          max-width: 100%;
          width: 100%;
        }
        .second {
          max-width: 100%;
          width: 100%;
          margin-top: 0px;
          .cta_image_bg {
            height: 350px;
          }
          div {
            img {
              width: 100%;
              height: auto;
            }
          }
        }
      }
    }

}

@media only screen and (max-width: 1000px) {
    .desktopmenu,
    .pageheader {
        display: none;
    }

    .mobileheader {
        display: block;
        visibility: visible;
        .flex {
            padding: 10px;
        }
    }

    .sitetitle h1 {
        font-size: 30px;
        line-height: 36px;
    }

    header#header .mobileheader > .flex {
        border-bottom: 2px solid $yale-menu;
        padding-left: 0px;
        padding-right: 0px;
        margin-left: 20px;
        margin-right: 0px;
        margin-bottom: 10px;
    }

    .search-form div.form-wrapper {
        padding: 0px;
        max-width: auto;
        width: auto;
        margin: 0px;
    }

    .node-component-inline-callout-box {
        padding-right: 10px;
    }
}

@media only screen and (max-width: 960px) {
    .node-content-landing-page .field-name-body,
    .node-type-content-landing-page main .field-name-body,
    .node-type-content-landing-page main .pane-custom,
    article.node-content-news .greycontentborder > div.field-name-body {
        padding-right: 40px;
    }
}

@media only screen and (max-width: 959px) {
    div.node-component-three-wide-cta-boxes {
        .content.flex {
            -webkit-flex-direction: column;
            flex-direction: column;
            > div {
                width: 100%;
                max-width: 100%;
                border: none;
                margin-bottom: 2px;
            }
        }
    }
}

@media only screen and (max-width: 830px) {
    .worldmapitems {
        height: 550px;
    }
    .worldmapnames {
        margin-left: 0px;
        top: 300px;
    }
    #hometitle h2 {
        top: 20px;
    }
    .worldmapitems .worldmapitem,
    .worldmapitems .defaultworldmapitem {
        right: 0px;
        width: 110%;
    }
}

@media only screen and (max-width: 830px) {
    .worldmapitems {
        height: 700px;
    }
}



@media only screen and (max-width: 768px) {



    article.node-content-news .backgroundimage,
    body.node-type-content-landing-page main .backgroundimage,
    body.node-type-content-landing-pagenotriangle main .backgroundimage {
        position: relative;
        right: 0px;
        img {
            height: auto;
            width: 100%;
        }
    }

    body.node-type-content-landing-page main {
        .contenttitle {
            .headertop {
                height: auto;
            }
        }
    }

    article.node-content-news header {
        .headertop {
            height: auto;
        }
    }

    body.node-type-content-landing-page {
        main {
            .contenttitle {
                height: auto;
            }
        }
    }

    article.node-content-news {
        header {
            min-height: 100px;
            height: auto;
            .headertop {
                min-height: 100px;
            }
        }
    }

    article.node-content-news {
      .greycontentborder {
        padding-left: 40px
      }
      .greycontentbordercontent {
        padding-left: 0px;
        padding-right: 0px;
        .newsbody {
          padding-right: 0px;
          .field-name-body {
            padding-right: 10px;
          }
        }
      }
      figure.newsimage {
        figcaption {
          width: 100%;
          justify-content: space-between;
          padding-left: 30px;
          padding-right: 30px;
          .first {
            max-width: 60%;
            padding-right: 20px;
          }
        }
      }
      figure.verticalimage {
        figcaption {

          justify-content: space-between;

        }
      }
    }

    .sitetitle h1 {
        font-size: 30px;
        line-height: 36px;
    }

    .worldmapitems {
        height: 620px;
    }

    .worldmapitems .defaultworldmapitem img {
        width: 100%;
    }

    article.node-content-news .backgroundimage,
    body.node-type-content-landing-page main .backgroundimage,
    body.node-type-content-landing-pagenotriangle main .backgroundimage {
        height: auto;
    }

    .regionmap.maxwidth,
    .regionmapcontent {
        height: auto;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }

    .node-component-cta-with-image {
      .content {
        .second {
          .cta_image_bg {
            max-height: 300px;
          }
        }
        .first {
          .cta_image_bg {
            max-height: 300px;
          }
        }
      }
    }

    article.node-content-news {
      .headertop,
      .headerbottom {
        padding: 0px 0px;
      }
      .greycontentborder {
        figure.newsimage {
          .second {
            padding-right: 20px;
          }
        }
      }
      .maxwidth {
        padding-left: 20px;
        .greycontentbordercontent {
          padding: 0px 30px;
          padding-right: 30px;
          padding-top: 20px;
        }
      }
    }

    .view-all-news {
      div.node-content-news {
        .flex {
          flex-direction: column;
        }
        .left {
          max-width: 100%;
          width: 100%;
          img {
            max-width: 100%;
            width: 100%;
          }
        }
      }
    }

}

@media only screen and (max-width: 767px) {

    .node-component-horizontal-link-bar {
      padding: 40px;
      .content {
        .flex {
          flex-direction: column;
        }
      }
    }

    div.view-related-news-by-term {
        .views-row {
            .views-field-field-image {
                float: none;
            }
            .views-field-title,
            .views-field-body {
                margin: 0px;
            }
        }
    }

    .bean-stay-connected-block .content {
        -webkit-flex-direction: column;
        flex-direction: column;
        padding: 10px;
        padding-top: 40px;
    }

    .node-component-inline-callout-box {
      .content.flex {
        > .flex {
          -webkit-flex-direction: column;
          flex-direction: column;
          > div {
            width: 100%;
            max-width: 100%;
          }
        }
      }
    }

    footer#footer {
        height: auto;
        padding-top: 175px;
        .flex {
            -webkit-align-items: flex-start;
            align-items: flex-start;
        }
        > .maxwidth {
            padding: 0px 10px;
            > .flex {
                -webkit-flex-direction: column;
                flex-direction: column;
                height: auto;
                .firstcol {
                    margin-bottom: 10px;
                    .left {
                        margin-bottom: 10px;
                    }
                    .right {
                        margin-bottom: 10px;
                        div:first-child {
                            margin-bottom: 10px;
                        }
                        nav {
                            ul {
                                li {
                                    margin-right: 20px;
                                }
                            }
                        }
                    }
                }
                > .flex {
                    -webkit-flex-direction: column;
                    flex-direction: column;
                }
            }
        }
        li {
            img {
                margin: 0px 20px 0px 0px
            }
        }
    }

    body.front {
        footer#footer {
            padding-top: 200px;
            margin-top: -200px;
            // > .maxwidth {
            //     > .flex {

            //     }
            // }
        }
    }

    .node-content-news {
        .flex {
            -webkit-flex-direction: column;
            flex-direction: column;
            .first {
              max-width: 60%;
              padding-right: 20px;
            }
            .left {
                max-width: 100%;
                width: 100%;
                margin: 0px;
                img {
                    max-width: 100%;
                    width: 100%;
                }
            }
        }
    }

    .node-content-news {
      .greycontentbordercontent {
          figure.verticalimage {
            margin: 0px;
            margin-bottom: 40px;
            margin-left: 15px;
            width: 35%;
          }
      }
    }

}


@media only screen and (max-width: 730px) {
    #hometitle h2 {
        margin-left: 0px;
    }

    .view-all-news ul.pager li {
      display: inline-block;
      height: 45px;
      margin-bottom: 10px;
    }

}

@media only screen and (max-width: 661px) {
  .newsfilters.flex {
    flex-direction: column;
  }
}

@media only screen and (max-width: 480px) {

  div.node-component-news-list-by-term {
    div.arrowlink {
      a.flex {
        height: 105px;
      }
    }
  }

  button.filtershowhide {
    width: 90px;
    padding-right: 40px;
  }

  header#header .mobileheader > .flex {
      border-bottom: 2px solid $yale-menu;
      padding-left: 0px;
      padding-right: 0px;
      margin-left: 10px;
      margin-right: 0px;
      margin-bottom: 10px;
  }


  article.node-type-content-news {
    .newsbody {
      .verticalimage {
        float: none;
        max-width: 100%;
        height: auto;
        margin-left: 0px;
        margin-bottom: 0px;
      }
    }
  }


  .node-type-content-news {
    .greycontentbordercontent {
      figure.verticalimage {
        width: 100%;
        max-width: 100%;
        padding: 0px 0px;
        margin: 0px;
        figcaption {
          flex-direction: column;
          .first {
            order: 2;
          }
          .second {
            order: 1;
            margin-bottom: 10px;
            margin-top: 10px
          }
        }
      }
    }
  }

    article.node-content-news {
      header {
        .headertop,
        .headerbottom {
          padding: 0px 0px;
          h2 {
            // font-size: 50px;
            // line-height: 56px;
          }
        }
        .headerbottom {
          padding-top: 20px;
        }
      }
      .maxwidth {
        .greycontentbordercontent {
          padding-left: 20px;
          padding-right: 20px;
        }
      }
     figure.newsimage {
       figcaption {
         padding-left: 20px;
         flex-direction: column;
         .first {
           order: 2;
           max-width: 100%;
           padding: 0px 0px;
         }
         .second {
           order: 1;
           padding: 0px 0px;
         }
       }
     }
     figure.verticalimage {
       float: none;
       max-width: 100%;
     }

    }

    #hometitle h2 {
        margin-left: 0px;
        margin-right: 0px;
    }

    #hometitle h2 {
        max-width: 480px;
        font-size: 42px;
        line-height: 51px;
    }

    .worldmapnames {
        top: 200px;
        > div {
            max-width: 100%;
            margin-right: 0px;
        }
    }

    .worldmapitems {
        height: 740px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .worldmapitems .worldmapitem,
    .worldmapitems .defaultworldmapitem {
        top: 0px;
        right: 0px;
        width: 100%;
    }

    .worldmapitems .worldmapitem img,
    .worldmapitems .defaultworldmapitem img {
        max-width: 100%;
        height: auto;
        top: 0px;
        right: 0px;
    }

    .slideshowimage img {
        height: 300px;
    }

    .node-component-slideshow-with-text {
        .field-name-field-display-title {
            font-size: 30px;
            line-height: 36px;
        }
        .field-name-body {
            font-size: 19px;
            line-height: 33px;
        }
    }

    div.node-component-two-wide-cta-boxes.greyboxes > .flex {
        -webkit-flex-direction: column;
        flex-direction: column;
        > div {
            width: 100%;
            margin-bottom: 10px;
            > h3 {
                font-size: 26px;
                line-height: 42px;
            }
            p {
                font-size: 16px;
                line-height: 28px;
            }
        }
    }

    div.node-component-two-wide-cta-boxes.blueboxes {
        > .content.flex{
            > div {
                padding: 40px;
            }
        }
    }


    div.node-component-news-list-by-term {
        .content {
            .flex {
                -webkit-flex-direction: column;
                flex-direction: column;
                h3 {
                    font-size: 30px;
                    line-height: 28px;
                    margin: 0px;
                    margin-bottom: 10px;
                    margin-left: 10px;
                    margin-top: 10px;
                }
                .optionallink {
                    padding: 0px;
                }
            }
        }

    }

    div.node-component-news-list-by-term .content .view .view-content {
        margin-left: 0px;
    }

    div.view-related-news-by-term {
        .views-row {
            margin-bottom: 20px;
            .views-field-field-image {
                float: none;
                max-width: 100%;
                img {
                    max-width: 100%;
                }
            }
            .views-field-title,
            .views-field-body {
                margin: 0px;
                padding: 0px;
            }
            .views-field-title {
                margin-top: 10px;
            }
            .views-field-body {
                padding-bottom: 10px;
            }
        }
    }

    .regionmapcontent .regiontitle {
        -webkit-flex-direction: column;
        flex-direction: column;
    }

    .regionmapcontent .regiondataitems figure {
        padding: 5px 0px;
    }

    .regionmap.maxwidth,
    .regionmapcontent {
        height: auto;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }



}

@media only screen and (max-width: 420px) {}

@media only screen and (max-width: 414px) {

    .yalebanner {
        .maxwidth {
            max-width: 414px;
            width: 100%;
            margin: 0px auto;
            padding: 0px;
            padding: 0px 10px;
        }
        a {
            padding-left: 0px;
        }
    }

    #hometitle h2 {
        max-width: 390px;
        font-size: 42px;
        line-height: 51px;
    }

}

@media only screen and (max-width: 375px) {
    #hometitle h2 {
        max-width: 355px;
        font-size: 38px;
        line-height: 40px;
    }

    div.node-component-video-player-banner {
        .content {
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    .node-component-slideshow-with-text {
        .left {
            margin-left: 0px;
            margin-right: 0px;
        }
    }

    body.node-type-content-homepage div.node-component-two-wide-cta-boxes.greyboxes > .content.flex > div,
    body.node-type-content-homepage div.node-component-two-wide-cta-boxes.blueboxes > .content.flex > div {
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media only screen and (max-width: 320px) {}
