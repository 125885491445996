////////////////////////
// Base Partials
//
// These files will be shared across all three of your output CSS files. Generally included here are only Compass Extension imports and imports for variables, functions, mixins, and extendables.
//
// Toolkit imports all of Compass, so there is no need to import it separately.
////////////////////////

////////////////////////
// Compass Extensions
////////////////////////
//@import 'breakpoint';
//@import 'singularitygs';
//@import 'toolkit';
//@import 'toolkit/kickstart';

////////////////////////
// Private Imports
////////////////////////
//@import 'variables';
//@import 'functions';
//@import 'mixins';
//@import 'extendables';

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  
  body * {
    box-sizing: border-box;
  }